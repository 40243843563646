import React, { useState, useRef } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { WidgetProps } from "@rjsf/core";

import TermsAndConditions from "./TermsAndConditions";


const TermsAndConditionsWidget = (props) => {
  const [checked, setChecked] = useState(false);
  const [scrollBottomReached, setScrollBottomReached] = useState(false);
  const textRef = useRef(null);

  const { value, onChange } = props;

  const handleScroll = () => {
    const element = textRef.current;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      setScrollBottomReached(true);
    }
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
    onChange(event.target.checked);
  };

  return (
    <>
      <Box
        ref={textRef}
        onScroll={handleScroll}
        sx={{
          border: "1px solid #ccc",
          padding: "10px",
          height: "700px",
          overflowY: "scroll",
          mb: 2,
        }}
      >
        <TermsAndConditions />
      </Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={handleChange}
            disabled={!scrollBottomReached}
          />
        }
        label="I accept the terms and conditions"
      />
    </>
  );
};

export default TermsAndConditionsWidget;
