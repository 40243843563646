import { ConstructionOutlined } from "@mui/icons-material";
// import { authBackend } from "../Backend";

import { supabaseClient } from "../supabase";

export const fetchCurrentUser = async () => {

  // const recipient_id = JSON.parse(localStorage.recipient_id);

  const { data: { user } } = await supabaseClient.auth.getUser()
  return user;

};

export const LogoutUser = async () => {
  const { error } = await supabaseClient.auth.signOut()
  console.log("Loggins out user.", error)
  if (error) {
    return {}
  } else {
    return error
  }

};
