import {
  Avatar,
  TextField,
  Button,
  CircularProgress,
  Alert,
  IconButton,
  Select,
  Paper,
  Grid,
  Typography,
  Stack,
  Box,
  FormControl,
  InputLabel,
} from "@mui/material";
import React, { useState, useRef, useContext } from "react";
import CameraField from "./CameraField";
import CancelIcon from "@mui/icons-material/Cancel";
import InputIcon from "@mui/icons-material/Input";

import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import PreviewImage from "./PreviewImage";
import { Close } from "@mui/icons-material";
import FileField from "./FileField";

import { UserContext } from "../../App";

import UserAvatar from "./UserAvatar";

import useCreatePost from "./useCreatePost";

import {
  loadRelationships,
  RelationshipAvatar,
} from "../Relationships/RelationshipAvatar";

import SharingWidget from "./SharingWidget";

const defaultValues = {
  name: "",
  message: "",
  image: {},
};

dayjs.extend(utc);
dayjs.extend(timezone);
const user_tz = dayjs.tz.guess()

function LogEntry({ initialData, profilePic, user, timestamp, handleClose, handleSubmit }) {
  const [message, setMessage] = useState(initialData?.message || "");
  const [privacy, setPrivacy] = useState("All");

  const todays_date = dayjs().format().slice(0, 10)
  const [date, setDate] = useState(dayjs(todays_date));
  const [postImages, setPostImages] = useState([]);

  const [blob, setBlob] = useState(null);
  const [postImage, setPostImage] = useState(null);
  const [previewImage, setPreviewImage] = useState("");
  const [isImageCaptured, setIsImageCaptured] = useState(false);

  const update_id = initialData?.id || undefined;
  // console.log("ENTRY INITIAL DATA", initialData);

  var initialSharing = undefined;
  if (initialData) {
    initialSharing = {
      share_everyone: initialData.share_everyone,
      share_relationships: initialData.share_relationships,
      share_users: initialData.share_users
    }
  }

  // console.log("LOG ENTRY WIDGET!")

  const [logSharing, setLogSharing] = useState(initialSharing);

  const { userState } = useContext(UserContext);

  const handleMessageChange = (e) => {
    const { name, value } = e.target;
    setMessage(value);
  };

  const fileRef = useRef();

  const handleImageChange = (e) => {
    setPostImage(e.target.files[0]);

    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      setBlob(null);
      setIsImageCaptured(false);
      setPreviewImage(reader.result);

      setPostImages([
        ...postImages,
        { data: reader.result, name: e.target.files[0].name },
      ]);
    };
  };

  function handleCloseDialog() {
    handleClose();
    setMessage("");
    setPrivacy("All");
    setDate(dayjs(todays_date));
    setPostImages([]);

    // uiDispatch({ type: "SET_POST_MODEL", payload: false });
  }
  function removeFileImage(item) {
    // setPostImages([ ...postImages.slice(0,imageIndex , ...postImages.slice(imageIndex+1))])
    setPostImages(postImages.filter((prevItem) => prevItem !== item));
    setPreviewImage("");
    setPostImage(null);
  }
  function removeCameraImage() {
    setBlob(null);
    setIsImageCaptured(false);
  }

  function showCapturedImage() {
    if (blob) {
      let blobURL = URL.createObjectURL(blob);

      return (
        <>
          <Alert>
            <b>Image Size ({Math.round(blob.size / 1024)} Kb)</b>
          </Alert>
          <img src={blobURL} style={{ width: "100%", height: "100%" }} alt="" />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "16px",
              marginBottom: "16px",
            }}
          >
            <IconButton onClick={removeCameraImage} size="medium">
              <Avatar style={{ background: "tomato", color: "white" }}>
                <Close />
              </Avatar>
            </IconButton>
          </div>
        </>
      );
    }
  }

  const { handleSubmitPost, loading, error } = useCreatePost({
    update_id,
    message,
    date,
    privacy,
    postImages,
    handleSubmit,
    logSharing,
  });

  const hostpath = window.location.hostname.split(".");
  const subdomain = hostpath[0] == "192" ? "test1" : hostpath[0];


  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Paper elevation={3}>
        <Box width="99%" sx={{ padding: 2 }}>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={8} md={5}>
              <Stack direction="row" spacing={3}>
                <RelationshipAvatar
                  size={30}
                  relationship_id={userState.currentUser.relationship.id}
                />
                <Box>
                  <Typography variant="h5">
                    {user.first_name} {user.last_name}
                  </Typography>
                  <Typography sx={{ display: { xs: "none", sm: "block" } }} >{timestamp}</Typography>
                </Box>
              </Stack>
            </Grid>
            <Grid
              item
              xs={3}
              md={5}
              align="left"
              sx={{ display: { xs: "none", md: "block" } }}
            >
              {!(subdomain.startsWith("cop-")) &&
                <SharingWidget allowEmpty={false} value={logSharing} onChange={setLogSharing} />
              }

              {/* <FormControl>
                <InputLabel>Privacy</InputLabel>

                <Select
                  label="Privacy"
                  native
                  value={privacy}
                  onChange={(e) => setPrivacy(e.target.value)}
                >
                  <option value={"All"}>All</option>
                  <option value={"Family"}>Family</option>
                  <option value={"Caregivers"}>Caregivers</option>
                </Select>
              </FormControl> */}
            </Grid>
            <Grid
              item
              xs={4}
              md={2}
              align="right"
              sx={{ display: { xs: "none", sm: "block" } }}
            >
              <DatePicker
                label="Date"
                disableFuture
                timezone={user_tz}
                value={date}
                onChange={(newValue) => {
                  setDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField align="center" {...params} />
                )}
              />{" "}
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              align="right"
              sx={{ display: { xs: "block", sm: "none" } }}
            >
              <MobileDatePicker
                label="Date"
                disableFuture
                value={date.tz(user_tz)}
                onChange={(newValue) => {
                  setDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField align="center" {...params} />
                )}
              />{" "}
            </Grid>

            <Grid
              item
              xs={12}
              align="left"
              sx={{ display: { xs: "block", md: "none" } }}
            >
              <SharingWidget allowEmpty={false} value={logSharing} onChange={setLogSharing} />
            </Grid>

          </Grid>

          <Grid
            item
            container
            spacing={2}
            style={{ margin: "2px 2px 2px 2px" }}
          >
            {postImages.map((item, index) => {
              return (
                <Grid item spacing={2}>
                  <Alert>
                    <b>Image Size ({Math.round(item.data.length / 1024)} Kb)</b>
                  </Alert>
                  <PreviewImage
                    key={index}
                    previewImage={item.data}
                    removeFileImage={() => removeFileImage(item)}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
        <TextField
          id="name-input"
          name="message"
          label="type here"
          type="text"
          multiline
          value={message}
          onChange={handleMessageChange}
          sx={{ border: "none", width: "99%" }}
          style={{ margin: "2px 2px 2px 2px" }}
          error={error !== ""}
          helperText={error}
        />

        <Box width="99%" sx={{ padding: 2 }}>
          <Grid container spacing={2} alignItems="center" justify="center">
            <Grid item xs={3} align="center">
              <FileField fileRef={fileRef} />
            </Grid>

            <Grid item xs={3} align="center">
              <CameraField
                setBlob={setBlob}
                isImageCaptured={isImageCaptured}
                setIsImageCaptured={setIsImageCaptured}
                setPreviewImage={setPreviewImage}
                setPostImages={setPostImages}
                postImages={postImages}
              />
            </Grid>

            <Grid item xs={3} display="flex" justify="center">
              <Button
                disabled={loading}
                onClick={handleSubmitPost}
                variant="contained"
                color="primary"
                style={{ width: "100%" }}
                startIcon={<InputIcon />}
              >
                {loading ? (
                  <CircularProgress
                    variant="indeterminate"
                    size={25}
                    style={{ color: "#fff" }}
                  />
                ) : (
                  <Typography sx={{ display: { xs: "none", sm: "block" } }}>
                    " Submit"
                  </Typography>
                )}
              </Button>
            </Grid>
            <Grid item xs={3} align="center">
              <Button
                onClick={handleCloseDialog}
                variant="contained"
                color="secondary"
                style={{ width: "100%" }}
                startIcon={<CancelIcon />}
              >
                <Typography sx={{ display: { xs: "none", sm: "block" } }}>
                  Cancel
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
        <input
          type="file"
          style={{ display: "none" }}
          ref={fileRef}
          onChange={handleImageChange}
          accept="image/*"
          capture="user"
        />
      </Paper>
    </LocalizationProvider>
  );
}

export default LogEntry;
