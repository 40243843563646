



export const themeOptions = {
    typography: {
        fontFamily: "Quicksand,Mulish",
        allVariants: {
            color: "black"
        },
    },
    palette: {
        primary: {
            main: '#87479C',
            dark: '#4C2E87',
            // contrastText: '#242420'
        },
        secondary: {
            main: '#095296',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    fontSize: "1rem",
                    margin: "0px",
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    margin: "0px",
                    padding: "0px",
                },
            },
            defaultProps: {
                margin: "dense",
                size: "small",
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    margin: "0px",
                    padding: "0px",
                },
            },
            defaultProps: {
                margin: "dense",
                size: "small",
            },
        },
    },
};
