import React, { useState, useContext, useEffect } from "react";

import { DataGrid, useGridApiRef } from '@mui/x-data-grid';

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

import { supabaseClient } from "../supabase";
import { UIContext } from "../../App";

const defaultRowData = [
    { "id": 1, "ServiceNeeds": "Getting in and out of bed", "Resource": "" },
    { "id": 2, "ServiceNeeds": "Dressing", "Resource": "" },
    { "id": 3, "ServiceNeeds": "Grooming", "Resource": "" },
    { "id": 4, "ServiceNeeds": "Toileting", "Resource": "" },
    { "id": 5, "ServiceNeeds": "Eating", "Resource": "" },
    { "id": 6, "ServiceNeeds": "Walking", "Resource": "" },
    { "id": 7, "ServiceNeeds": "Getting out of chair", "Resource": "" },
    { "id": 8, "ServiceNeeds": "Preparing Meals", "Resource": "" },
    { "id": 9, "ServiceNeeds": "Doing laundry", "Resource": "" },
    { "id": 10, "ServiceNeeds": "Light housework", "Resource": "" },
    { "id": 11, "ServiceNeeds": "Shopping ", "Resource": "" },
    { "id": 12, "ServiceNeeds": "Transportation", "Resource": "" },
    { "id": 13, "ServiceNeeds": "Using telephone", "Resource": "" },
    { "id": 14, "ServiceNeeds": "Managing money", "Resource": "" },
    { "id": 15, "ServiceNeeds": "Administering medications ", "Resource": "" },
    { "id": 16, "ServiceNeeds": "Exercise", "Resource": "" },
    { "id": 17, "ServiceNeeds": "Social Events", "Resource": "" },
    { "id": 18, "ServiceNeeds": "Making and attending medical appointments", "Resource": "" },
    { "id": 19, "ServiceNeeds": "Emotional/Spiritual", "Resource": "" },
]

const columns = [
    // { field: 'id', headerName: 'ID', width: 90 },
    {
        field: 'ServiceNeeds',
        headerName: 'Service/Need',
        flex: 0.6,
        // minWidth: 230,
        editable: false,
    },
    {
        field: 'Resource',
        flex: 1,
        headerName: 'Resource',
        // width: 150,
        editable: true,
    },
];


export default function ServicesTable() {
    // {/* <Box sx={{ height: 400, width: '100%' }}> */}
    // <Box sx={{ height: 400, width: '100%' }}>
    const { uiDispatch } = useContext(UIContext);

    const [rowdata, setRowData] = useState(defaultRowData);

    const apiRef = useGridApiRef();

    const fetchServiceNeeds = async () => {
        const recipient_id = localStorage.recipient_id;
        const { data: recipientData } = await supabaseClient.from('recipients').select('id,service_needs').eq('id', recipient_id)
        const serviceNeeds = recipientData[0].service_needs;
        if (serviceNeeds) {
            const rowData = defaultRowData.map((item, index) => {
                return { id: item.id, ServiceNeeds: item.ServiceNeeds, Resource: (serviceNeeds[item.ServiceNeeds]) ? serviceNeeds[item.ServiceNeeds].Resource : "" }
            })
            setRowData(rowData)
        }
    };


    const handleUpdate = async () => {
        const allRows = apiRef.current.getAllRowIds().map((id) => apiRef.current.getRow(id)); // Get all row data

        const rowData = {};
        allRows.forEach((row) => {
            rowData[row.ServiceNeeds] = {
                Resource: row.Resource
            }
        })

        const recipients_id = localStorage.recipient_id;

        const response = await supabaseClient.from('recipients').update({ service_needs: rowData }).eq('id', recipients_id);
        if (response.error) {
            uiDispatch({
                type: "SET_MESSAGE",
                payload: {
                    color: "error",
                    display: true,
                    text: "Update Failed.",
                },
            });
            console.log("ServiceNeeds Update Failed:", response)
        } else {
            uiDispatch({
                type: "SET_MESSAGE",
                payload: {
                    color: "success",
                    display: true,
                    text: "Updated.",
                },
            });
        }
    };

    useEffect(() => {
        fetchServiceNeeds();
    }, []);

    return (
        <>
            <Box
                // width={"100%"}
                // display="flex"
                alignItems="center"
                justifyContent="center"
            // m="auto"
            >
                <Paper variant="elevation" elevation={2}>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        // spacing={2}
                        padding={2}
                    >
                        <Grid item>
                            <Stack direction="row" justifyContent="space-between">
                                <Typography variant="h5" gutterBottom>
                                    Service Needs Matrix
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item>

                            <DataGrid
                                apiRef={apiRef}
                                rows={rowdata}
                                disableColumnResize={true}
                                columns={columns}
                                disableColumnSorting
                                disableColumnFilter
                                disableColumnMenu={true}
                                autoHeight
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 19,
                                        },
                                    },
                                }}
                                pageSizeOptions={[19]}
                                // checkboxSelection
                                disableRowSelectionOnClick
                            />
                            <Button
                                onClick={handleUpdate}
                                // disabled={!validated}
                                sx={{ margin: 1 }}
                                variant="contained"
                            >
                                Update </Button>

                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </>
    )
}
