import React, { useState, useContext, useEffect } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";

import { RelationshipAvatar } from "../Relationships/RelationshipAvatar";
import { UserModal } from "./UserModal";

import { deActivateUser, loadUsers } from "../Users/useUsers";

import { authBackend } from "../Backend";

import { supabaseClient } from "../supabase";

import { UIContext, UserContext } from "../../App";
import { ConstructionOutlined } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";


function DeleteDialog({ confirmOpen, handleConfirmClose }) {

  return (
    <Dialog
      open={confirmOpen}
      onClose={() => handleConfirmClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to remove this user from the community?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleConfirmClose(false)} color="primary">
          Cancel
        </Button>
        <Button onClick={() => handleConfirmClose(true)} color="primary" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}


export default function DenseTable() {
  const { userState, userDispatch } = useContext(UserContext);
  const { uiDispatch } = useContext(UIContext);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalId, setModalId] = useState(0);
  const [modalEdit, setModalEdit] = useState(false);

  const [selectedId, setSelectedId] = useState(0);
  const [confirmOpen, setConfirmOpen] = useState(false);


  const handleConfirmClose = (confirmed) => {
    setConfirmOpen(false);
    if (confirmed) {
      deActivateUser(selectedId)
      loadUsers().then((data) => {
        userDispatch({
          type: "SET_USERS",
          payload: data,
        });
      })
    }
  };


  const handleDeleteRecord = async (selectedId) => {
    setSelectedId(selectedId);
    setConfirmOpen(true);
  };

  const handleEditRecord = (selectedId) => {
    setModalEdit(true);
    setModalId(selectedId);
    setModalOpen(true);
  };

  const handleAddRecord = () => {
    setModalEdit(false);
    setModalOpen(true);
  };

  const handleOpenModal = () => {
    setModalEdit(false);
    setModalId(0);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    // loadUsers();
  };

  const handleSubmitModal = () => {
    setModalOpen(false);

    loadUsers().then((data) => {
      console.log("LOAD USERS RESULT", data);
      userDispatch({
        type: "SET_USERS",
        payload: data,
      });
    })
    // loadUsers().then((data) => {
    //   // setData({ ...data, result: data.result });

    //   userDispatch({
    //     type: "SET_USERS",
    //     payload: data.result,
    //   });
    // });
    // setModalOpen(false);
  };

  // const tableData = userState.users.map((item, index) => {
  //   return {
  //     first_name: item.first_name,
  //     last_name: item

  //     key: index + 100,
  //     title: item.first_name + " " + item.last_name,
  //     id: item.id,
  //     group: item.relationship.name,
  //   }

  const UserRelationshipAvatar = ({ relationship_id }) => {
    const my_relationship = userState.relationships.find(
      (x) => x.id == relationship_id
    );
    return (
      <>
        {my_relationship && (
          <RelationshipAvatar
            size={25}
            label={my_relationship.name}
            configString={my_relationship.icon}
          />
        )}
      </>
    );
  };

  const handleValidate = (formData) => {
    console.log("HANDLE VALIDATE ", formData);
    // if the number of PCs is 1 and this is the poa then do not allow the formdata to be anything but PC

    var poaList = [];
    var poa_relationship_id = 0;
    console.log("handleValidate userState", userState);
    userState.users.forEach((user) => {
      if (user.relationship_types.name == "PC") {
        poaList.push(user.id);
        poa_relationship_id = user.relationship_types.id;
      }
    });
    if (
      poaList.includes(modalId) &&
      poaList.length === 1 &&
      formData.relationship_id !== poa_relationship_id
    ) {
      return {
        field: "relationship_id",
        message:
          "Can not change the relationship of the only PC for this site.",
      };
    }

    return;
  };

  const TableRowItem = ({ row }) => {
    return (
      <TableRow
        key={row.id}
      // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell>
          <Stack direction="row" spacing={2}>
            <UserRelationshipAvatar
              relationship_id={row.relationship_types.id}
            />
            <p> </p>
            {row.profiles.first_name} {row.profiles.last_name}
          </Stack>
        </TableCell>
        {/* <TableCell>{row.last_name}</TableCell> */}
        <TableCell width={50} align="right">
          <Stack direction="row">
            <IconButton
              aria-label="edit"
              onClick={() => {
                handleEditRecord(row.profiles.id);
              }}
            >
              <EditIcon />
            </IconButton>
            {row.relationship_types.name !== "PC" &&
              <IconButton
                aria-label="delete"
                onClick={() => {
                  handleDeleteRecord(row.profiles.id);
                }}
              >
                <DeleteIcon />
              </IconButton>
            }
          </Stack>
        </TableCell>
      </TableRow>
    )
  }

  console.log("USERSTABLE ", userState)

  console.log("USERSTABLE USERS", userState.users)
  return (
    <>
      <DeleteDialog confirmOpen={confirmOpen} handleConfirmClose={handleConfirmClose} />

      <UserModal
        modalOpen={modalOpen}
        modalId={modalId}
        modalEdit={modalEdit}
        handleSubmit={handleSubmitModal}
        handleClose={handleCloseModal}
        onValidate={handleValidate}
      />
      {!modalOpen && (
        <Box
          // width={"100%"}
          // display="flex"
          alignItems="center"
          justifyContent="center"
        // m="auto"
        >
          <Paper variant="elevation" elevation={2}>
            <Grid
              container
              direction="column"
              justify="center"
              // spacing={2}
              padding={2}
            >
              <Grid item>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h5" gutterBottom>
                    Users
                  </Typography>
                  <Button variant="contained" onClick={handleAddRecord}>
                    Add
                  </Button>
                </Stack>
              </Grid>
              <Grid item>
                <TableContainer
                  style={{ maxHeight: 640 }}
                  sx={{ mt: 3.5 }}
                  component={Paper}
                >
                  {/* <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table"> */}
                  <Table stickyHeader size="small" aria-label="a dense table">
                    {/* <TableHead>
                      <TableRow>
                        <TableCell>First Name</TableCell>
                        <TableCell>Last Name</TableCell>
                        <TableCell width={50}>Actions</TableCell>
                      </TableRow>
                    </TableHead> */}
                    <TableBody>
                      {userState?.users && userState.users.filter((row) => row.active == true).filter((row) => row.relationship_types.name == "PC").map((row) => (
                        <TableRowItem row={row} />
                      ))}
                      {userState?.users && userState.users.filter((row) => row.active == true).filter((row) => row.relationship_types.name != "PC").map((row) => (
                        <TableRowItem row={row} />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      )}
    </>
  );
}
