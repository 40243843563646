import React, { useContext, useState } from "react";

import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  Link,
} from "@mui/material";

import Form from "@rjsf/mui";

import { Link as RouterLink, useNavigate } from "react-router-dom";

import { authBackend, publicBackend } from "../Backend";

import { RJSFSchema, UiSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv6";

const url = window.location.protocol + '//' + window.location.hostname
import axios from "axios";

const [current_subdomain, ...rest_hostpath] = window.location.hostname.split(".");
const base_domain = rest_hostpath.join('.');


// import { withTheme } from '@rjsf/core';
// import Theme from '@rjsf/mui';
// Make modifications to the theme with your own fields and widgets
// const Form = withTheme(Theme);

import { UIContext } from "../../App";

const PasswordReset = () => {
  const { uiDispatch } = useContext(UIContext);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const schema = {
    title: "Password Reset",
    description:
      "Upon submitting your request a password reset link will be sent to your email.",
    type: "object",
    required: ["email"],
    properties: {
      email: {
        type: "string",
        format: "email",
        title: "Email",
        minLength: 3,
      },
    },
  };

  const ui_schema = {
    username: {
      "ui:autofocus": true,
      // "ui:description": "Login Username",
    },
    "ui:submitButtonOptions": {
      submitText: "Request Reset",
      norender: false,
      props: {
        disabled: false,
        className: "btn btn-info",
      },
    },
  };

  const formSubmit = async ({ formData }, e) => {
    // console.log("Data submitted: ", formData);

    setLoading(true);

    var submit_data = formData;

    submit_data.redirect = `${window.location.protocol}//portal.${base_domain}/setpassword`;

    console.log("Data submitted: ", submit_data);


    await axios({
      method: "post",
      url: `${url}/public/passwordreset`,
      data: submit_data
    })
      .then((data) => {
        setLoading(false);

        uiDispatch({
          type: "SET_MESSAGE",
          payload: { color: "success", display: true, text: "Password Reset Request sent!" },
        });

        navigate("/login");
      })
      .catch((err) => {
        console.log("ERROR",err)
        // if (err.response.status === 422) {
        //   setError({ ...err.response.data.error });
        // }

        // if (err.response.status === 400) {
        uiDispatch({
          type: "SET_MESSAGE",
          payload: {
            color: "error",
            display: true,
            text: err.message,
          },
        });
        // }
      });
  };

  return (
    <>
      <Box
        width={"80vh"}
        display="flex"
        alignItems="center"
        justifyContent="center"
        m="auto"
      >
        <Paper variant="elevation" elevation={2}>
          <Grid
            container
            direction="column"
            justify="center"
            spacing={2}
            padding={2}
          >
            <Grid item>
              <Form
                schema={schema}
                uiSchema={ui_schema}
                validator={validator}
                onSubmit={formSubmit}
              />
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  );
};

export default PasswordReset;
