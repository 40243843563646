import { useContext, useState } from "react";
// import axios from "axios";
import { UIContext } from "../../App";
import { UserContext } from "../../App";
import { useNavigate } from "react-router-dom";

import { v4 as uuidV4 } from "uuid";

import { decode } from 'base64-arraybuffer'

// import { authBackend } from "../Backend";

import { supabaseClient } from "../supabase";

const useCreatePost = ({
  update_id,
  message,
  date,
  privacy,
  postImages,
  handleSubmit,
  logSharing,
}) => {
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState("");
  const { userState } = useContext(UserContext);

  const { uiDispatch } = useContext(UIContext);
  const navigate = useNavigate();

  const createPost = async (data) => {
    setLoading(true);

    const submit_method = data.id ? "patch" : "post";

    console.log("POST DATA", data);

    // const images = data.images;

    // data.images.forEach(async ({ data, name }) => {

    //   const result = await supabaseClient.storage.from('images').upload(`test/${name}`, decode(base64_data), {
    //     contentType: mimetype
    //   });
    // })

    console.log("USERSTATE", userState);

    const post_data = {
      date: data.date,
      profiles_id: userState.currentUser.id,
      // privacy: data.privacy,
      message: data.message,
      meta_text: `${userState.currentUser.first_name} ${userState.currentUser.last_name} ${data.date}`,
      recipients_id: data.recipient,
      share_everyone: data.share_everyone,
      share_relationships: data.share_relationships,
      share_users: data.share_users
    }

    const query_string = '*'
      + ',post_images(id,file)'
      + ',profiles(id,first_name,last_name'
      + ',recipient_users(id,relationship_types(id,name)))'
      + ',comments(id,message'
      + ',profiles(id,first_name,last_name'
      + ',recipient_users(id,relationship_types(id,name)))'
      + ')'

    const result = await supabaseClient.from('posts').insert(post_data).select(query_string);

    // console.log("POST DATA RESULT", result)


    if (result.eror) {
      setLoading(false);
      if (result.error) {
        uiDispatch({
          type: "SET_MESSAGE",
          payload: {
            display: true,
            text: result.error.message,
            color: "error",
          },
        });
      }
      console.log(error);
    } else {

      const post_res_data = result.data[0];
      // console.log("SUPABASE POST DATA RESPONSE", post_res_data);
      // upload images
      for (let i = 0; i < data.images.length; i++) {

        const fileExt = data.images[0].name.split('.').pop();
        const image_name = `${uuidV4()}.${fileExt}`
        const image_data = data.images[0].data
        const recipient_id = localStorage.recipient_id
        const mimetype = image_data.substring(image_data.indexOf(":") + 1, image_data.indexOf(";"));
        const base64_data = image_data.split('base64,')[1]
        const image_path = `${recipient_id}/${image_name}`
        const { data: upload_res_data } = await supabaseClient.storage.from('images').upload(image_path, decode(base64_data), {
          contentType: mimetype
        });

        const post_image = {
          posts_id: post_res_data.id,
          file: {
            bucket: 'images',
            path: image_path
          },
          recipients_id: recipient_id
        }
        const { data: image_res_data } = await supabaseClient.from('post_images').insert(post_image).select('id,file')
        post_res_data.post_images.push(image_res_data[0])
      }

      handleSubmit(result.data[0])
      // handleSubmit({ id: data.id, ...data.result });

      setLoading(false);
      // postDispatch({ type: 'ADD_POST', payload: result.data[0] })
      // uiDispatch({
      //   type: "SET_MESSAGE",
      //   payload: {
      //     color: "success",
      //     display: true,
      //     text: data.message,
      //   },
      // });
      uiDispatch({ type: "SET_POST_MODEL", payload: false });
      navigate("/discussions");
    }


    // .then((data) => {
    //     console.log ("SUPABASE POST DATA RESPONSE", data)
    //     handleSubmit({ id: data.id, ...data.result });

    //     setLoading(false);
    //     // postDispatch({ type: 'ADD_POST', payload: response.data.post })
    //     // uiDispatch({
    //     //   type: "SET_MESSAGE",
    //     //   payload: {
    //     //     color: "success",
    //     //     display: true,
    //     //     text: data.message,
    //     //   },
    //     // });
    //     uiDispatch({ type: "SET_POST_MODEL", payload: false });
    //     navigate("/discussions");
    // }).catch((error) => {
    //   setLoading(false);
    //   if (error ) {
    //     uiDispatch({
    //       type: "SET_MESSAGE",
    //       payload: {
    //         display: true,
    //         text: error,
    //         color: "error",
    //       },
    //     });
    //   }
    //   console.log(error);
    // })

    // authBackend({
    //   method: submit_method,
    //   path: "post/",
    //   payload: data,
    // })
    //   .then((data) => {
    //     handleSubmit({ id: data.id, ...data.result });

    //     setLoading(false);
    //     // postDispatch({ type: 'ADD_POST', payload: response.data.post })
    //     // uiDispatch({
    //     //   type: "SET_MESSAGE",
    //     //   payload: {
    //     //     color: "success",
    //     //     display: true,
    //     //     text: data.message,
    //     //   },
    //     // });
    //     uiDispatch({ type: "SET_POST_MODEL", payload: false });
    //     navigate("/discussions");
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //     if (err && err.response) {
    //       uiDispatch({
    //         type: "SET_MESSAGE",
    //         payload: {
    //           display: true,
    //           text: err.response.data.error,
    //           color: "error",
    //         },
    //       });
    //     }
    //     console.log(err);
    //   });

    // let token = localStorage.token && JSON.parse(localStorage.getItem("token"));

    // try {
    //   const response = await axios.post(`${url}/api/v1/post/`, data, {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   });

    //   handleSubmit({ id: response.data.id, ...response.data.result });

    //   setLoading(false);
    //   // postDispatch({ type: 'ADD_POST', payload: response.data.post })
    //   uiDispatch({
    //     type: "SET_MESSAGE",
    //     payload: {
    //       color: "success",
    //       display: true,
    //       text: response.data.message,
    //     },
    //   });
    //   uiDispatch({ type: "SET_POST_MODEL", payload: false });
    //   navigate("/discussions");
    // } catch (err) {
    //   setLoading(false);
    //   if (err && err.response) {
    //     uiDispatch({
    //       type: "SET_MESSAGE",
    //       payload: {
    //         display: true,
    //         text: err.response.data.error,
    //         color: "error",
    //       },
    //     });
    //   }
    //   console.log(err);
    // }
  };

  const createUserPost = async (images = []) => {
    const recipient_id = localStorage.recipient_id;

    console.log("POST DATA MESAGE", message, message ? true : false);
    console.log(
      "POST DATA images",
      postImages,
      postImages.length ? true : false
    );

    if (message || postImages.length) {

      var submit_data = {
        date: date.format("YYYY-MM-DD"),
        message: message,
        privacy: "1",
        recipient: recipient_id,
        images: postImages,
        share_everyone: logSharing ? logSharing.share_everyone : false,
        share_relationships: logSharing ? logSharing.share_relationships : ",,",
        share_users: logSharing ? logSharing.share_users : ",,",
      };
      if (update_id) {
        submit_data.id = update_id
      }
      await createPost(submit_data);
      setError("");
    } else {
      setError("Message must contain either text or an image!");
    }

    return;
  };

  const handleSubmitPost = () => {
    createUserPost();
  };

  return {
    handleSubmitPost,
    loading,
    error,
  };
};

export default useCreatePost;
