import axios from "axios";
// const url = 'http://192.168.1.114:12001'
const url = window.location.protocol + '//' + window.location.hostname

// Schedule Blocks Impelmentation
//  https://codesandbox.io/s/custom-cell-action-n02dv?file=/src/App.js:608-767

// TEST CONFIGURATION
// export const PayPalConfig = {
//   clientId:
//     "AZOTLwjUxzc00l-GnHIDjB2dEmYgkvUUwGMeX0nRBf1tPjNXAzkgiojthiOpjCQZHX3Jp2CIo-FwlC7i",
//   plans: [
//     {
//       const: "P-0S733165NL516073GMTRHUZA",
//       title: "Monthly [ $24.99/month + tax ]",
//     },
//     {
//       const: "P-7LR26803SC7708426MTRHVZI",
//       title: "Semi-Annual (15% discount) [ $21.25/month + tax ]",
//     },
//   ],
// };


// // OFFICIAL CONFIGURATION
export const newUserPayPalConfig = {
  clientId:
    "ATSa8qjQLXWEvkJ2aTalBw44ZW7I0NlGvUN56GBVMHU9xGDWrXK_DSTeuqtzx-UmEswh_6M5TEcI0sHk",
  plans: [
    {
      const: "P-484914607H0770052MXLGR5Y",
      title: "Free Trial + Monthly [ $24.99/month + tax ]",
    },
    // {
    //   const: "P-4H377187VU6131143MTQSUYQ",
    //   title: "Monthly [ $24.99/month + tax ]",
    // },

    {
      const: "P-5TT70388SH171623EM2G2KWQ",
      title: "3-Month (15% discount) [ $21.24/month + tax ]",
    },
    // {
    //   const: "P-4CJ21484JS304852UMTR2FAI",
    //   title: "Semi-Annual (15% discount) [ $42.49/month + tax ]",
    // },
  ],
};

const userExists = async (username = "") => {
  const { data } = await axios({
    url: `${url}/public/finduser`,
    params: { "email": username }
  });
  if (data) {
    return data.exists;
  }
};

export const newUserSteps = [
  {
    // state 0
    title: "My Account",
    formData: {},
    schema: {
      title: "User Profile",
      // "description": "ThinkBeyond Login",
      type: "object",
      required: [
        "email",
        "first_name",
        "last_name",
        "new_password",
        "confirm_password",
        // "subscription",
      ],
      properties: {
        email: {
          type: "string",
          format: "email",
          title: "Email",
          minLength: 3,
          readOnly: true
        },
        first_name: {
          type: "string",
          title: "First Name",
          minLength: 2,
        },
        last_name: {
          type: "string",
          title: "Last  Name",
          minLength: 2,
        },
        mobile_phone: {
          type: "string",
          title: "Mobile Phone",
          minLength: 10,
        },
        new_password: {
          type: "string",
          format: "password",
          title: "New Password",
          minLength: 6,
        },
        confirm_password: {
          type: "string",
          format: "password",
          title: "Confirm Password",
          minLength: 6,
        },
        // discount_code: {
        //   type: "string",
        //   title: "Discount Code"
        // },
        // subscription: {
        //   type: "string",
        //   title: "Subscription Period",
        //   oneOf: newUserPayPalConfig.plans,
        //   default: "P-484914607H0770052MXLGR5Y",
        // },
      },
    },
    ui_schema: {
      email: {
        "ui:autofocus": true,
        "ui:help": "Double check to make sure your email is typed correctly.",
        // "ui:description": "Login Username",
      },
      new_password: {
        "ui:widget": "password",
        "ui:help": "For your own protection, make it a strong password.",
      },
      confirm_password: {
        "ui:widget": "password",
        "ui:help": "",
      },
      // discount_code: {
      //   "ui:help": "(If applicable) Discount will be applied at Verify stage.",
      // },

      "ui:submitButtonOptions": {
        submitText: "Update",
        norender: false,
        props: {
          disabled: false,
          className: "btn btn-info",
        },
      },

      mobile_phone: {
        "ui:widget": "PhoneNumberInput",
      },
    },

    // nextState: (data) => {
    //   return data.recipient_type == 2 ? 4 : 3;
    // },
    validate: (data, trueHandler, falseHandler) => {
      userExists(data.email).then((res) => {
        var extra_errors = {};

        if (data.new_password !== data.confirm_password) {
          extra_errors["confirm_password"] = {
            __errors: ["Passwords much match!"],
          };
        }

        if (res) {
          extra_errors["email"] = {
            __errors: ["Email already exists!"],
          };
        }

        if (Object.keys(extra_errors).length !== 0) {
          falseHandler(extra_errors);
        } else {
          trueHandler();
        }
      });
    },
  },
  {
    // state 3
    title: "My Senior",
    formData: {},
    schema: {
      title: "Your Dependent Senior",
      // "description": "ThinkBeyond Login",
      type: "object",
      required: [
        "recipient_first_name",
        "recipient_last_name",
        "recipient_gender",
        "recipient_language",
        "recipient_birth_date",
        "recipient_nickname",
        // "recipient_poa_forms",  // Commented out for Beta testing.
      ],
      properties: {
        recipient_first_name: {
          type: "string",
          title: "First Name",
          minLength: 2,
        },
        recipient_last_name: {
          type: "string",
          title: "Last  Name",
          minLength: 2,
        },
        recipient_gender: {
          type: "string",
          title: "Gender",
          oneOf: [
            { const: "Female", title: "Female" },
            {
              const: "Male",
              title: "Male",
            },
          ],
          default: "Female",
        },
        recipient_language: {
          type: "string",
          title: "Language",
          oneOf: [
            { const: "en", title: "English" },
            {
              const: "fr",
              title: "French",
            },
          ],
          default: "en",
        },
        recipient_birth_date: {
          type: "string",
          title: "Birth Date ( yyyy-mm-dd )",
          format: "date",
          default: "1950-01-01",
        },
        recipient_nickname: {
          type: "string",
          title: "Recipient Nickname",
          minLength: 2,
        },
        // recipient_poa_forms: {
        //   type: "array",
        //   title: "Proof of Authority",
        //   description:
        //     "Upload documented proof of your authority to make decisions for your dependent senior in PDF format.",
        //   items: {
        //     type: "string",
        //     format: "data-url",
        //   },
        // },
      },
    },
    validate: (data, trueHandler, falseHandler) => {

      // assessPOAform


      // recipientExists(data.recipient).then((res) => {
      //   console.log("RECIPIENT EXISTS", res);
      //   if (res) {
      //     falseHandler({
      //       recipient_subdomain: {
      //         __errors: ["Recipient already exists!"],
      //       },
      //     });
      //   } else {
      trueHandler();
      //   }
      // });
    },
    // ui_schema: {
    //   recipient_poa_forms: {
    //     "ui:options": {
    //       accept: ".pdf",
    //     },
    //   },
    // },
  },
  {
    // state 3
    title: "Terms",
    formData: {},
    schema: {
      title: "Terms & Conditions",
      // "description": "ThinkBeyond Login",
      type: "object",
      required: [
        "tc_accepted",
        // "recipient_poa_forms",  // Commented out for Beta testing.
      ],
      properties: {
        tc_accepted: {
          type: "boolean",
          title: "Accepted",
          default: false
        },
        // recipient_poa_forms: {
        //   type: "array",
        //   title: "Proof of Authority",
        //   description:
        //     "Upload documented proof of your authority to make decisions for your dependent senior in PDF format.",
        //   items: {
        //     type: "string",
        //     format: "data-url",
        //   },
        // },
      },
    },
    validate: (data, trueHandler, falseHandler) => {
      if (data.tc_accepted) {
        trueHandler();
      } else {
        falseHandler({ tc_accepted: { __errors: ["Terms and Conditions must be accepted."] } });
      }
    },
    ui_schema: {
      tc_accepted: {
        "ui:widget": "TermsAndConditionsWidget",
      },
    },
  },


  // {
  //   // state 4
  //   title: "My Site",
  //   formData: {},
  //   schema: {
  //     title: "SeniorSynCare Site",
  //     // "description": "ThinkBeyond Login",
  //     type: "object",
  //     required: ["recipient_subdomain", "texting_setup"],
  //     properties: {
  //       recipient_subdomain: {
  //         type: "string",
  //         title: "Recipient Subdomain",
  //         description:
  //           'ClartityCare will set up an special web address in the form of "http://<subdomain>.waitingforgod.ca" for you to access the app.',
  //         minLength: 3,
  //       },
  //       texting_setup: {
  //         type: "string",
  //         title: "Texting Setup",
  //         default:
  //           '{"country": " ", "region": " ", "district": " ", "area_code": " ", "phone_number": " "}',
  //       },
  //     },
  //   },

  //   ui_schema: {
  //     texting_setup: {
  //       "ui:widget": "PhoneNumbersWidget",
  //     },
  //   },
  //   validate: (data, trueHandler, falseHandler) => {
  //     recipientExists(data.recipient_subdomain).then((res) => {
  //       var extra_errors = {};

  //       const phone_json = JSON.parse(data.texting_setup);
  //       if (phone_json.phone_number === " ") {
  //         extra_errors["texting_setup"] = {
  //           __errors: ["Please select a texting number!"],
  //         };
  //       }

  //       if (res) {
  //         extra_errors["recipient_subdomain"] = {
  //           __errors: ["Subdomain already taken!"],
  //         };
  //       }

  //       if (Object.keys(extra_errors).length !== 0) {
  //         falseHandler(extra_errors);
  //       } else {
  //         trueHandler();
  //       }
  //     });
  //   },
  // },
  {
    // state 5
    title: "Verify",
    type: "object",
    schema: {
      title: "Verify Your Information",
      description:
        "Once you press the Submit button below, a confirmation email will be sent to your email address when your new site is ready!",
      type: "object",
      // properties: {
      properties: {
        email: {
          type: "string",
          title: "User Email",
          readOnly: true,
        },
        first_name: {
          type: "string",
          title: "User First Name",
          readOnly: true,
        },
        last_name: {
          type: "string",
          title: "User Last Name",
          readOnly: true,
        },
        // subscription: {
        //   type: "string",
        //   title: "Subscription Period",
        //   oneOf: newUserPayPalConfig.plans,
        //   readOnly: true,
        // },
        // new_password: {
        //   type: "string",
        //   title: "User Password",
        //   readOnly: true,
        // },
        recipient_first_name: {
          type: "string",
          title: "Recipient First Name",
          readOnly: true,
        },
        recipient_last_name: {
          type: "string",
          title: "Recipient Last Name",
          readOnly: true,
        },
        recipient_gender: {
          type: "string",
          title: "Gender",
          oneOf: [
            { const: "Female", title: "Female" },
            {
              const: "Male",
              title: "Male",
            },
          ],
          readOnly: true,
        },
        recipient_language: {
          type: "string",
          title: "Language",
          oneOf: [
            { const: "en", title: "English" },
            {
              const: "fr",
              title: "French",
            },
          ],
          readOnly: true,
        },
        recipient_birth_date: {
          type: "string",
          title: "Recipeint Birth Date",
          readOnly: true,
        },
        recipient_nickname: {
          type: "string",
          title: "Recipient Short Name",
          readOnly: true,
        },
        tc_accepted: {
          type: "boolean",
          title: "Terms and Conditions Accepted",
          readOnly: true,
        }
        // recipient_poa_forms: {
        //   type: "array",
        //   title: "Proof of Authority",
        //   items: {
        //     type: "string",
        //     // format: "data-url",
        //   },
        //   readOnly: true,
        // },
        // recipient_subdomain: {
        //   type: "string",
        //   title: "Recipient Subdomain",
        //   readOnly: true,
        // },
        // texting_setup: {
        //   type: "string",
        //   title: "Texting Setup",
        //   readOnly: true,
        // },
      },
    },

    ui_schema: {
      new_password: {
        "ui:widget": "password",
      },
      texting_setup: {
        "ui:widget": "PhoneNumbersWidget",
      },
    },
  },
];
