


import { createClient } from '@supabase/supabase-js'
// const sb_url = 'http://192.168.1.114:8000';
const sb_url = 'https://portal.seniorsyncare.com';

const url = window.location.protocol + '//' + window.location.hostname
                
console.log("SB_URL", process.env.REACT_APP_SUPABASE_URL?.toString());
console.log("SB_KEY", process.env.REACT_APP_SUPABASE_KEY?.toString());


// To access -- To get a single header, you can use JSON arrow operators in RLS
// SELECT current_setting('request.headers', true)::json->>'recipientId';

const recipientId = localStorage.recipient_id;

export const supabaseClient = createClient(
  // process.env.REACT_APP_SUPABASE_URL?.toString(),
  sb_url,
  process.env.REACT_APP_SUPABASE_KEY?.toString(), {
  db: {
    schema: "public",
  },
  auth: {
    persistSession: true,
  },
  global: {
    headers: { 'recipientId': recipientId },
  }
});
