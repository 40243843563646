import { parseISO, addDays } from "date-fns";

import {
  getInitialRepeat,
  parseICS,
  getRRuleStarts,
  calcUntil,
  maxDate
} from "./useRepeat";

// const url = 'http://192.168.1.114:12001'
const url = window.location.protocol + '//' + window.location.hostname

import axios from "axios";

import LoopIcon from "@mui/icons-material/Loop";

import { supabaseClient } from "../supabase";

export const categories = [
  { value: 1, name: "General", color: "" },
  { value: 2, name: "Social", color: "green" },
  { value: 3, name: "Healthcare", color: "orange" },
  { value: 4, name: "Available", color: "gray" },
];

export const getCategory = (id) => {
  return categories.find((category) => category.value === id);
};

export const getCategoryIndex = (id) => {
  return categories.findIndex((category) => category.value === id);
};

export const eventFields = [
  { name: "db_id", type: "hidden" },
  { name: "repeat", type: "hidden" },
  { name: "duration", type: "hidden" },
  {
    name: "type",
    type: "select",
    options: categories,
    default: 1,
    config: {
      label: "Type",
      required: true,
      errMsg: "Plz Select Event Type",
    },
  },
  {
    name: "description",
    type: "input",
    default: "",
    config: { label: "Details", multiline: true, rows: 4 },
  },
];

export const confirmPublicEvent = async (event, action) => {
  const recipient_id = localStorage.recipient_id;

  // This was an available event, and now its a public event.

  if (action === "edit") {

    var payload;
    var icsString = "";
    if (event.repeat.ics !== "") {
      const rruleSet = event.repeat.rruleSet;

      var today = new Date();
      const today_offset = today.getTimezoneOffset();
      const gmt_offset = event.start.getTimezoneOffset();
      const tz_offset = (today_offset - gmt_offset) / 60
      var new_date = new Date(event.start)
      new_date.setHours(new_date.getHours() + tz_offset)

      rruleSet.exdate(new_date);
      icsString = rruleSet.toString();

      payload = {
        id: event.db_id,
        recipients_id: recipient_id,
        description: event.description || "",
        start: event.start,
        end: event.end,
        title: event.title || "",
        repeat: false,
        ics: icsString,
        share_everyone: event.sharing.share_everyone,
        share_relationships: event.sharing.share_relationships,
        share_users: event.sharing.share_users,
        type: 2, // set event to Social event.
      };
    } else {
      payload = {
        id: event.db_id,
        recipients_id: recipient_id,
        description: event.description || "",
        start: event.start,
        end: event.end,
        title:
          typeof event.title === "string"
            ? event.title
            : event.title.props?.children[2],
        repeat: false,
        ics: "",
        type: 2, // set event to Social event.
      }
    }

    const { data } = await axios({
      method: 'post',
      url: `${url}/public/event_confirm`,
      data: payload
    });

    return {
      event_id: event.event_id,
      title: "Reserved!",
      start: event.start,
      end: event.end,
      type: 2,
      status: 1,
      disabled: true,
    };
  }
};

export async function fetchPublicEvents(queryData) {
  // Expected query =
  // ?start=Sun Dec 11 2022 00:00:00 GMT-0500 (Eastern Standard Time)&end=Sat Dec 17 2022 23:59:59 GMT-0500 (Eastern Standard Time)

  // const queryData = fetchQueryToDates(query);

  const recipient_id = localStorage.recipient_id;

  const startDate = new Date(queryData.start);
  const endDate = new Date(queryData.end);

  const { data } = await axios({
    url: `${url}/public/events`,
    params: { start: startDate.toISOString(), end: endDate.toISOString(), recipients_id: recipient_id }
  });

  return dbToSchedulerEvents(data, startDate, endDate)
}

export const fetchQueryToDates = (query) => {
  // Expected query =
  // ?start=Sun Dec 11 2022 00:00:00 GMT-0500 (Eastern Standard Time)&end=Sat Dec 17 2022 23:59:59 GMT-0500 (Eastern Standard Time)

  var queryData = {};
  var pairs = (query[0] === "?" ? query.substr(1) : query).split("&");
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("=");
    queryData[pair[0]] = pair[1];
  }

  return queryData;
};

export const fetchRemote = async (queryData) => {
  return await fetchEvents(queryData.start, queryData.end);
};

export const fetchEvents = async (startDateString, endDateString) => {
  const startDate = new Date(startDateString);
  const endDate = new Date(endDateString);

  const queryStart = startDate.toISOString();
  const queryEnd = endDate.toISOString();

  const { data: events } = await supabaseClient.from('events').select('*').gt('end', queryStart).lt('start', queryEnd)

  return dbToSchedulerEvents(events, startDate, endDate)
};

const dbToSchedulerEvents = (events_in, startDate, endDate) => {
  var events = [];

  var eventId = 1;
  events_in.forEach((item, index) => {
    if (item.ics) {
      const repeat = parseICS(item.ics);
      getRRuleStarts(repeat, startDate, addDays(endDate, 2)).forEach(
        (ruleStart, ruleindex) => {

          var eventStart = new Date(Date.parse(ruleStart));
          var eventEnd = new Date(
            Date.parse(ruleStart) + item.duration * 1000
          );

          const apply_fix = true;
          if (apply_fix === true) {
            var today = new Date();
            const today_offset = today.getTimezoneOffset();
            const gmt_offset = eventStart.getTimezoneOffset();
            const tz_offset = (today_offset - gmt_offset) * 60000
            if ((eventStart.getUTCHours() >= 0) && (eventStart.getUTCHours() < (today_offset / 60))) {
              eventStart.setDate(eventStart.getDate() - 1);
              eventEnd.setDate(eventEnd.getDate() - 1);
            }
            eventStart.setHours(eventStart.getHours() - tz_offset)
            eventEnd.setHours(eventEnd.getHours() - tz_offset)
          }

          const ruleEvent = {
            event_id: eventId++,
            db_id: item.id,
            repeat: repeat,
            title: (
              <>
                <LoopIcon /> {item.title || ""}
              </>
            ),
            duration: item.duration,
            start: parseISO(eventStart.toISOString()),
            end: parseISO(eventEnd.toISOString()),
            color: getCategory(item.type).color,
            description: item.description || "",
            type: getCategoryIndex(item.type),
            status: 1,
            sharing: {
              share_everyone: item.share_everyone,
              share_relationships: item.share_relationships || ",,",
              share_users: item.share_users || ",,",
            },
          };
          events.push(ruleEvent);
        }
      );
    } else {
      const repeat = getInitialRepeat(parseISO(item.start), parseISO(item.end));
      const event = {
        event_id: eventId++,
        db_id: item.id,
        repeat: repeat,
        title: item.title || "",
        start: parseISO(item.start),
        end: parseISO(item.end),
        duration: item.duration,
        color: getCategory(item.type).color,
        description: item.description || "",
        type: getCategoryIndex(item.type),
        status: 1,
        sharing: {
          share_everyone: item.share_everyone,
          share_relationships: item.share_relationships || ",,",
          share_users: item.share_users || ",,",
        },
      };
      // console.log("ADDING EVENT", event);
      events.push(event);
    }
  });

  // console.log("EVENTS ", events);

  return events;
};

export const confirmEvent = async (event, action) => {
  const recipient_id = localStorage.recipient_id;

  const isRepeat = event.repeat.freq > 0;
  const repeatStart = event.repeat.dtstart;

  var repeatEnd;
  if (isRepeat && event.repeat.criterion == 1) {
    repeatEnd = calcUntil(
      event.repeat.dtstart,
      event.repeat.freq,
      event.repeat.interval,
      event.repeat.count
    );
  } else if (isRepeat && event.repeat.criterion == 2) {
    repeatEnd = maxDate();
  } else {
    if (isRepeat) {
      repeatEnd = event.repeat.until;
    }
  }

  const payload = {
    recipients_id: recipient_id,
    description: event.description,
    start: isRepeat ? repeatStart : event.start,
    ics: event.repeat.ics,
    repeat: isRepeat,
    duration: event.duration,
    // duration: (event.end - event.start)/1000,
    end: isRepeat ? repeatEnd : event.end,
    title:
      typeof event.title === "string"
        ? event.title
        : event.title.props?.children[2],
    type: categories[event.type].value,
    share_everyone: event.sharing.share_everyone,
    share_relationships: event.sharing.share_relationships,
    share_users: event.sharing.share_users,
  }
  if (action === 'edit') {
    payload.id = event.db_id
  }

  const { data: events } = await supabaseClient.from('events').upsert(payload).select();
  const data = events[0]

  return {
    event_id: action === "edit" ? event.event_id : data.id,
    db_id: data.id,
    title: data.title,
    start: new Date(data.start),
    end: new Date(data.end),
    duration: data.duration,
    ics: data.ics,
    repeat: event.repeat,
    color: getCategory(data.type).color,
    description: data.description,
    type: getCategoryIndex(data.type),
    sharing: event.sharing,
    status: 1,
  };
};

export const handleDelete = async (event_id, db_id) => {
  await supabaseClient.from('events').delete().eq('id', db_id)
  return event_id;
};

export const deleteEvent = async (event_id, db_id) => {
  await supabaseClient.from('events').delete().eq('id', db_id)
  return event_id;
};
