import axios from "axios";
// const url = 'http://192.168.1.114:12001'
const url = window.location.protocol + '//' + window.location.hostname

// Schedule Blocks Impelmentation
//  https://codesandbox.io/s/custom-cell-action-n02dv?file=/src/App.js:608-767

// TEST CONFIGURATION
// export const PayPalConfig = {
//   clientId:
//     "AZOTLwjUxzc00l-GnHIDjB2dEmYgkvUUwGMeX0nRBf1tPjNXAzkgiojthiOpjCQZHX3Jp2CIo-FwlC7i",
//   plans: [
//     {
//       const: "P-0S733165NL516073GMTRHUZA",
//       title: "Monthly [ $24.99/month + tax ]",
//     },
//     {
//       const: "P-7LR26803SC7708426MTRHVZI",
//       title: "Semi-Annual (15% discount) [ $21.25/month + tax ]",
//     },
//   ],
// };

const userExists = async (username = "") => {
  const { data } = await axios({
    url: `${url}/public/finduser`,
    params: { "email": username }
  });
  if (data) {
    return data.exists;
  }
};

export const steps = [
  {
    // state 0
    title: "My Account",
    formData: {},
    schema: {
      title: "User Profile",
      // "description": "ThinkBeyond Login",
      type: "object",
      required: [
        "email",
        "first_name",
        "last_name",
        "new_password",
        "confirm_password",
      ],
      properties: {
        email: {
          type: "string",
          format: "email",
          title: "Email",
          minLength: 3,
          readOnly: true
        },
        relationship: {          
          type: "string",
          title: "First Name",
          minLength: 3
        },
        first_name: {
          type: "string",
          title: "First Name",
          minLength: 3,
        },
        last_name: {
          type: "string",
          title: "Last  Name",
          minLength: 3,
        },
        mobile_phone: {
          type: "string",
          title: "Mobile Phone",
          minLength: 10,
        },
        new_password: {
          type: "string",
          format: "password",
          title: "New Password",
          minLength: 6,
        },
        confirm_password: {
          type: "string",
          format: "password",
          title: "Confirm Password",
          minLength: 6,
        },
      },
    },
    ui_schema: {
      email: {
        "ui:autofocus": true,
        "ui:help": "Double check to make sure your email is typed correctly.",
        // "ui:description": "Login Username",
      },
      relationship: {
        "ui:widget": "hidden"
      },
      subdomain: {
        "ui:widget": "hidden"
      },
      new_password: {
        "ui:widget": "password",
        "ui:help": "For your own protection, make it a strong password.",
      },
      confirm_password: {
        "ui:widget": "password",
        "ui:help": "",
      },

      "ui:submitButtonOptions": {
        submitText: "Update",
        norender: false,
        props: {
          disabled: false,
          className: "btn btn-info",
        },
      },

      mobile_phone: {
        "ui:widget": "PhoneNumberInput",
      },
    },

    validate: (data, trueHandler, falseHandler) => {
      userExists(data.email).then((res) => {
        var extra_errors = {};

        if (data.new_password !== data.confirm_password) {
          extra_errors["confirm_password"] = {
            __errors: ["Passwords much match!"],
          };
        }

        if (Object.keys(extra_errors).length !== 0) {
          falseHandler(extra_errors);
        } else {
          trueHandler();
        }
      });
    },
  },
];
