import React, { useState, useContext, useEffect } from "react";

import { Avatar, Stack, TextField, Button, Typography, IconButton } from "@mui/material";

// import axios from "axios";
import CommentIcon from "@mui/icons-material/Comment";
import ClearIcon from "@mui/icons-material/Clear";

import UserAvatar from "./UserAvatar";

import { UserContext } from "../../App";

// import { authBackend } from "../Backend";

import { supabaseClient } from "../supabase";

import {
  loadRelationships,
  RelationshipAvatar,
} from "../Relationships/RelationshipAvatar";

function CommentEntry({ post, post_id, ProfilePic, onSubmit }) {
  const { userState } = useContext(UserContext);
  const [comment, setComment] = useState("");
  const [error, setError] = useState("");

  const handleCommentChange = (e) => {

    if (e == null) {
      setError("");
      setComment("");
    } else {

      const { name, value } = e.target;
      // if (value) {
      //   setError("");
      // } else {
      //   setError("Text cannot be empty!");
      // }
      setComment(value);
    }
  };

  async function handleSubmit(e, post_id, comment) {
    if (!comment) {
      setError("Text cannot be empty!");
      setComment(" ");
      return;
    }

    // let token = localStorage.token && JSON.parse(localStorage.getItem("token"));

    const recipient_id = localStorage.recipient_id

    const entry = {
      posts_id: post_id,
      profiles_id: userState.currentUser.id,
      meta_text: `${userState.currentUser.first_name} ${userState.currentUser.last_name}`,
      message: comment,
      recipients_id: recipient_id
    };

    const result = await supabaseClient.from('comments').insert(entry).select()

    console.log("COMMENT ENTRY SUBMIT RESPONSE", result)

    if (result.eror) {
      console.log("handleCommentSubmit ERROR");
      console.log(error.message);
    } else {
      console.log("SUPABASE COMMENT DATA RESPONSE", result.data)

      setComment("");
      // console.log("CommentEntry handleSubmit response");
      // console.log(data);
      onSubmit(result.data);
    }

    // authBackend({
    //   method: "post",
    //   path: "comment/",
    //   payload: {
    //     post: post_id,
    //     message: comment,
    //   },
    // })
    //   .then((data) => {
    //     setComment("");
    //     // console.log("CommentEntry handleSubmit response");
    //     // console.log(data);
    //     onSubmit(data.result);
    //   })
    //   .catch((err) => {
    //     console.log("handleCommentSubmit ERROR");
    //     console.log(err);
    //   });

    // axios
    //   .post(`${url}/api/v1/comment/`, data, {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   })
    //   .then((response) => {
    //     setComment("");
    //     onSubmit(response.data.result);
    //   })
    //   .catch((err) => {
    //     console.log("handleCommentSubmit ERROR");
    //     console.log(err);
    //   });
  }

  return (
    <Stack direction="row" spacing={2} style={{ margin: "8px 8px 8px 8px" }}>
      <RelationshipAvatar
        size={30}
        relationship_id={userState.currentUser.relationship.id}
      />

      {/* <UserAvatar user={userState.currentUser}/> */}
      {/* <Avatar src={ProfilePic} /> */}
      <TextField
        fullWidth
        value={comment}
        // id={post_id}
        multiline
        variant="filled"
        label="Comments, feedback, questions?"
        onChange={handleCommentChange}
        error={error !== ""}
        helperText={error}
        InputProps={comment ? {
          endAdornment: (
            <IconButton onClick={() => handleCommentChange(null)}>
              <ClearIcon />
            </IconButton >
          )
        } : undefined

        }
      />
      <Button
        // display={false}
        variant="contained"
        onClick={(e) => handleSubmit(e, post.id, comment)}
        startIcon={<CommentIcon />}
      >
        <Typography sx={{ display: { xs: "none", sm: "block" } }}>
          {" "}
          Comment
        </Typography>
      </Button>
    </Stack>
  );
}

export default CommentEntry;
