import React, {
    useContext,
    useEffect,
    useState,
    Fragment,
    createRef,
    useRef,
} from "react";

import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";

import {
    Box,
    Button,
    Container,
    Divider,
    CssBaseline,
    Grid,
    Paper,
    Typography,
    Link,
    InputLabel,
    Select,
    MenuItem,
    Stepper,
    Step,
    StepLabel,
} from "@mui/material";

import Form from "@rjsf/mui";
import jwtDecode from "jwt-decode";

import { RJSFSchema, UiSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv6";

import FileWidget from "./FileWidget";
import { FilesInfoWidget } from "./FileWidget";
import { UserSubmitModal } from "./UserSubmitModal";
import { authBackend, publicBackend } from "../Backend";

import { PhoneNumbersWidget } from "./PhoneNumbersWidget";

import { PhoneNumberInput } from "../PhoneNumberInput";

import PayPalSubscribe from "./PayPalSubscribe";

// import { PayPalConfig } from "./new_site";

// const url = 'http://192.168.1.114:12001'
const url = window.location.protocol + '//' + window.location.hostname
import axios from "axios";

const [current_subdomain, ...rest_hostpath] = window.location.hostname.split(".");
const base_domain = rest_hostpath.join('.');

// import { withTheme } from '@rjsf/core';
// import Theme from '@rjsf/mui';
// Make modifications to the theme with your own fields and widgets
// const Form = withTheme(Theme);

// https://codesandbox.io/s/agitated-mcnulty-c1bxd8?file=/src/components/SelectStepper/Steps/Step1.js

// https://mui.com/material-ui/react-stepper/

import { UIContext } from "../../App";


import { supabaseClient } from "../supabase";

// import { steps } from "./registerdata";

import { steps } from "./UserConfig";

import { createTheme, ThemeProvider, styled } from "@mui/material/styles";

import { themeOptions } from "./themeOptions";

// Schedule Blocks Impelmentation
//  https://codesandbox.io/s/custom-cell-action-n02dv?file=/src/App.js:608-767
const UserRegister = () => {
    const [activeStage, setActiveStage] = useState(0);
    const [activeState, setActiveState] = useState(0);
    const [completed, setCompleted] = useState({});
    const [extraErrors, setExtraErrors] = useState({});
    const [solutionProvided, setSolutionProvided] = useState(true);

    const [submitting, setSubmitting] = useState(true);
    const [showModal, setShowModal] = useState(false);

    const [formKey, setFormKey] = useState(Date.now());

    let { token } = useParams();

    const decodeToken = jwtDecode(token);
    console.log("JWT DECODE");
    console.log(decodeToken);

    // var ca = token;
    // // var base64Url = ca.split('.')[1];

    // // var decodedValue = JSON.parse(window.atob(base64Url));

    // // console.log("JWT DECODE 2", decodedValue);

    const navigate = useNavigate();
    // const [stepHistory, setStepHistory] = [];
    // const wiz_data = steps.map((item) => item.formData);

    var stateHistory = useRef([]);
    var initialData = {
        email: decodeToken?.email,
        relationship: decodeToken?.relationship,
        subdomain: decodeToken?.subdomain
    };
    var stages = [];

    steps.forEach((item, index) => {
        initialData = { ...initialData, ...item.formData };
        if (stages.indexOf(item.title) < 0) {
            stages.push(item.title);
        }
    });

    const [currentData, setCurrentData] = useState(initialData);

    const isStepOptional = (step) => {
        return steps[step].optional === true;
    };

    const nextPage = () => {
        stateHistory.current.push(activeState);

        if (steps[activeState].nextState !== undefined) {
            const next_state = steps[activeState].nextState(currentData);
            setActiveState(next_state);
            setActiveStage(stages.indexOf(steps[next_state].title));
        } else {
            setActiveState(activeState + 1);
            setActiveStage(stages.indexOf(steps[activeState + 1].title));
        }
        updateCompleted(true);
    };

    console.log("USER REGISTRATION");

    const formSubmit = async (formData) => {
        // const texting_setup = JSON.parse(formData.texting_setup);
        // const submit_data = { ...formData, texting_setup };



        var submit_data = formData;

        console.log("Data submitted: ", submit_data);
        console.log("JASON TEST 1")
        setSubmitting(true);

        // await publicBackend({
        //   method: "post",
        //   path: "registration/register",
        //   // payload: submit_data,
        //   payload: JSON.stringify({"email": "jwright@thinkbeyond.ai"}),
        //   // payload: submit_data,
        //   headers: {
        //     'Accept': 'application/json',
        //     'Content-Type': 'application/x-www-form-urlencoded'
        //   },

        // })

        await axios({
            method: "post",
            url: `${url}/registration/userregister`,
            // data: JSON.stringify({ "email": "jwright@thinkbeyond.ai" }),
            data: { "email": "jwright@thinkbeyond.ai" },
            data: submit_data
            // headers: {
            //   'Accept': 'application/json',
            //   'Content-Type': 'application/x-www-form-urlencoded'
            // }
        })
            .then((data) => {
                console.log("Returned submission data", data);
                setCurrentData({ ...currentData, ...data.result });
                // setShowModal(true);
                setSubmitting(false);

            })
            .catch((err) => {
                console.log("User Registration Submission ERROR");
                console.log(err);

                setSubmitting(false);
                if (data in err.response) {
                    uiDispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            color: "error",
                            display: true,
                            text: err.response.data.error,
                        },
                    });
                } else {
                    uiDispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            color: "error",
                            display: true,
                            text: "Registration Error!  Please contact administrator.",
                        },
                    });
                }
            });

        console.log("LOGGING INTO SITE");

        const { data: userdata, error } = await supabaseClient.auth.signInWithPassword({
            email: formData["email"],
            password: formData["new_password"],
        });

        const { data: magic_link } = await supabaseClient.functions.invoke("getmagiclink",
            {
                body: {
                    email: formData["email"],
                    subdomain: formData["subdomain"]
                }
            });

        // const { data: magic_link } = await axios({
        //     url: `${url}/authenticated/getmagiclink`,
        //     params: { email: formData["email"], subdomain: formData["subdomain"] }
        // });

        await supabaseClient.auth.signOut();

        const redirect = `${window.location.protocol}//${formData["subdomain"]}.${base_domain}/login/${magic_link.hashed_token}`;
        console.log("Redirecting to:", redirect);
        window.location.assign(redirect)


        // // setLoading(true);
        // try {
        //   const { data } = await axios.post(`${url}/api/v1/registration/session/`, formData);
        //   // localStorage.setItem("token", JSON.stringify(data.data.token));

        //   // const me = await fetchCurrentUser();
        //   // setLoading(false);

        //   // uiDispatch({
        //   //   type: "SET_MESSAGE",
        //   //   payload: { color: "success", display: true, text: data.message },
        //   // });

        // } catch (err) {
        //   // setLoading(false);

        //   console.log(err);
        //   if (err && err.response) {
        //     if (err.response.status === 422) {
        //       // setError({ ...err.response.data.error });

        //     }

        //     if (err.response.status === 400) {
        //       // uiDispatch({
        //       //   type: "SET_MESSAGE",
        //       //   payload: {
        //       //     color: "error",
        //       //     display: true,
        //       //     text: err.response.data.error,
        //       //   },
        //       // });
        //     }
        //   }
        // }
    };

    const handleNext = () => {
        // setExtraErrors({});

        // const validation_result = formRef.current.validateForm()
        // console.log("VALIDATION RESULT", validation_result)
        // return;
        // if (formRef.current.validateForm()) {
        if (formRef.current.validateForm()) {
            var errors = {};
            setExtraErrors();
            if (steps[activeState].validate !== undefined) {
                const extra_errors = steps[activeState]
                    // steps[activeState]
                    .validate(
                        currentData,
                        () => {
                            setExtraErrors({});
                            nextPage();
                        },
                        (errors) => {
                            setExtraErrors(errors);
                        }
                    );
            }
            setFormKey(Date.now());
        }

        if (activeState === steps.length - 1) {
            // This is the final state!
            if (currentData.new_password === currentData.confirm_password) {
                console.log("FORM SUBMITING!");
                // console.log(currentData);
                formSubmit(currentData);
            } else {
                console.log("Invalid validation!")
            }
        }
        // return;
        // }
        // }
    };

    const handleChange = ({ formData }, e) => {
        setCurrentData({ ...currentData, ...formData });
        if (formRef.current && formRef.current.validateForm()) {
            updateCompleted(true);
        }
    };

    const handleBack = () => {
        const prev_state = stateHistory.current.pop();
        setActiveState(prev_state);
        setActiveStage(stages.indexOf(steps[prev_state].title));
    };

    const formRef = createRef();

    const updateCompleted = (isCompleted = false) => {
        const newCompleted = completed;
        newCompleted[activeStage] = isCompleted;
        setCompleted(newCompleted);
        setSolutionProvided(isCompleted);
    };

    useEffect(() => {
        if (formRef.current && formRef.current.validateForm()) {
            updateCompleted(true);
            setSolutionProvided(true);
            // setExtraErrors({});
        } else {
            updateCompleted(false);
            setSolutionProvided(false);
            setExtraErrors({});
        }
    }, [activeState]);

    const customWidgets = {
        FileWidget: FileWidget,
        FilesInfoWidget: FilesInfoWidget,
        PhoneNumbersWidget: PhoneNumbersWidget,
        PhoneNumberInput: PhoneNumberInput,
    };

    const theme = createTheme(themeOptions);

    return (
        <>
            <ThemeProvider theme={theme}>
                <div>
                    <Container component="main" maxWidth="xs">
                        {/* <CssBaseline />        */}
                        {/* <h4>{activeStep}</h4> */}
                        <Box
                            sx={{
                                marginTop: 8,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <UserSubmitModal
                                modalOpen={showModal}
                                submitting={submitting}
                                formData={currentData}
                            />

                            <Typography
                                component="h1"
                                variant="h4"
                                sx={{
                                    color: "#095296",
                                    // textTransform: "uppercase",
                                    fontWeight: 700,
                                }}
                            >
                                {current_subdomain.startsWith('cop-') ? "SeniorSynCare Community of Practice Registration" : "SeniorSynCare Registration"}
                            </Typography>
                        </Box>
                        <Box noValidate sx={{ mt: 1, p: 1 }}>
                            {/* Stepper Module */}
                            <Box
                                sx={{
                                    marginTop: 3,
                                    alignItems: "center",
                                }}
                            >
                                {/* <Stepper activeStep={activeStep} orientation="vertical"> */}
                                <Stepper nonLinear activeStep={activeStage}>
                                    {/* <Stepper activeStep={activeStep}> */}
                                    {/* {stages.map((stepTitle, index) => { */}
                                    {stages.map((stepTitle, index) => {
                                        const stepProps = { completed: completed[index] };
                                        const labelProps = {};
                                        // if (isStepSkipped(index)) {
                                        //     stepProps.completed = false;
                                        // }
                                        return (
                                            <Step key={stepTitle} {...stepProps}>
                                                <StepLabel {...labelProps}>{stepTitle}</StepLabel>
                                            </Step>
                                        );
                                    })}
                                </Stepper>
                            </Box>

                            {/* Active Step  */}
                            {activeState !== steps.length ? (
                                <>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            {/* <React.Suspense fallback="Loading Form View..">
                    {view}
                  </React.Suspense> */}
                                            {/* "hello" */}
                                            <Form
                                                key={formKey}
                                                formData={currentData}
                                                schema={steps[activeState].schema}
                                                uiSchema={steps[activeState].ui_schema}
                                                validator={validator}
                                                onSubmit={() => handleNext()}
                                                onChange={handleChange}
                                                extraErrors={extraErrors}
                                                // onError={onError}
                                                showErrorList="none"
                                                ref={formRef}
                                                widgets={customWidgets}
                                            // liveValidate
                                            >
                                                <Fragment />
                                            </Form>
                                        </Grid>
                                    </Grid>
                                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                                        {activeState > 0 && (
                                            <>
                                                <Button
                                                    color="inherit"
                                                    disabled={activeState === 0}
                                                    onClick={handleBack}
                                                    sx={{ mr: 1 }}
                                                >
                                                    Back
                                                </Button>
                                            </>
                                        )}
                                        <Box sx={{ flex: "1 1 auto" }} />
                                        {isStepOptional(activeState) && (
                                            <>
                                                {allowSkip && (
                                                    <Button
                                                        color="inherit"
                                                        onClick={handleSkip}
                                                        sx={{ mr: 1 }}
                                                    >
                                                        Skip
                                                    </Button>
                                                )}
                                            </>
                                        )}

                                        <Button
                                            variant="contained"
                                            // disabled={!solutionProvided}
                                            onClick={() => handleNext()}
                                        >
                                            {activeState === steps.length - 1 ? "Submit" : "Next"}
                                        </Button>
                                    </Box>
                                </>
                            ) : (
                                <></>
                            )}
                        </Box>
                    </Container>
                </div>
            </ThemeProvider>
        </>
    );
};

export default UserRegister;








