import React, { useContext, useState, useEffect, createRef } from "react";

import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  Link,
} from "@mui/material";

import Form from "@rjsf/mui";

import { RJSFSchema, UiSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";

import { supabaseClient } from "../supabase";

import { fetchCurrentUser } from "./AuthService";

import jwtDecode from "jwt-decode";
// import { withTheme } from '@rjsf/core';
// import Theme from '@rjsf/mui';
// Make modifications to the theme with your own fields and widgets
// const Form = withTheme(Theme);

import { UIContext, UserContext } from "../../App";

import { PhoneNumberInput } from "../PhoneNumberInput";

import { createTheme, ThemeProvider, styled } from "@mui/material/styles";

import axios from "axios";
// const url = 'http://192.168.1.114:12001'
const url = window.location.protocol + '//' + window.location.hostname

const userExists = async (username = "") => {
  const { data } = await axios({
    url: `${url}/public/finduser`,
    params: {"email": username}
  });
  if (data) {
    return data.exists;
  }
};


// const userExists = async (username = "") => {
//   const data = await publicBackend({
//     // const { data } = await publicBackend({
//     path: "find/",
//     filters: [{ col: "email", opr: "eq", value: username }],
//   }).then((data) => {
//     return data;
//   });

//   return data.result.exists;

//   // const querystring = rison.encode({
//   //   filters: [
//   //     {
//   //       col: "email",
//   //       opr: "eq",
//   //       value: username,
//   //     },
//   //   ],
//   // });
//   // const { data } = await axios.get(`${url}/api/v1/find/?q=${querystring}`);

//   // return data.result.exists;
// };

const themeOptions = {
  typography: {
    fontFamily: "Quicksand,Mulish",
    allVariants: {
      color: "black"
    },
  },
  palette: {
    primary: {
      main: '#9C528B',
      dark: '#201E50',
      // contrastText: '#242420'
    },
    secondary: {
      main: '#083D77',
    },
  },
  components: {
    MuiButton: {
      // defaultProps: {
      //   size: "medium",
      // },
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          textTransform: "none",
          fontSize: "1rem",
          margin: "0px",
          // padding: "0px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          margin: "0px",
          padding: "0px",
        },
      },
      defaultProps: {
        margin: "dense",
        size: "small",
      },
    },
  },

  // palette: {
  //   mode: "light",
  //   primary: {
  //     main: "#3fb550",
  //   },
  //   secondary: {
  //     main: "#b5114c",
  //   },
  // },
  // // overrides: {
  // //   MuiButton: {
  // //     root: {
  // //       size: "small",
  // //       textTransform: "none",
  // //     },
  // //   },
  // //   MuiTextField: {
  // //     margin: "dense",
  // //     size: "small",
  // //   },
  // // },
  // // props: {
  // //   MuiList: {
  // //     dense: true,
  // //   },
  // //   MuiMenuItem: {
  // //     dense: true,
  // //   },
  // //   MuiTable: {
  // //     size: "small",
  // //   },
  // //   MuiButton: {},
  // //   MuiButtonGroup: {
  // //     size: "small",
  // //   },
  // //   MuiCheckbox: {
  // //     size: "small",
  // //   },
  // //   MuiFab: {
  // //     size: "small",
  // //   },
  // //   MuiFormControl: {
  // //     margin: "dense",
  // //     size: "small",
  // //   },
  // //   MuiFormHelperText: {
  // //     margin: "dense",
  // //   },
  // //   MuiIconButton: {
  // //     size: "small",
  // //   },
  // //   MuiInputBase: {
  // //     margin: "dense",
  // //   },
  // //   MuiInputLabel: {
  // //     margin: "dense",
  // //   },
  // //   MuiRadio: {
  // //     size: "small",
  // //   },
  // //   MuiSwitch: {
  // //     size: "small",
  // //   },
  // //   MuiTextField: {
  // //     margin: "dense",
  // //     size: "small",
  // //   },
  // },
};

const Profile = () => {
  const { uiDispatch } = useContext(UIContext);
  const [loading, setLoading] = useState(false);
  const { userState, userDispatch } = useContext(UserContext);
  const [formData, setFormData] = useState({});

  const [extraErrors, setExtraErrors] = useState({});

  const schema = {
    // title: "User Profile",
    // "description": "ThinkBeyond Login",
    type: "object",
    required: ["username"],

    properties: {
      username: {
        type: "string",
        format: "email",
        title: "Email",
        minLength: 3,
      },
      first_name: {
        type: "string",
        title: "First Name",
        minLength: 3,
      },
      last_name: {
        type: "string",
        title: "Last  Name",
        minLength: 3,
      },
      mobile_phone: {
        type: "string",
        title: "Mobile Phone",        
        // minLength: 3,
      },
      home_phone: {
        type: "string",
        title: "Home Phone",
        default_value: ""
        // minLength: 3,
      },
      language: {
        type: "string",
        title: "Language",
        oneOf: [
          { const: "en", title: "English" },
          { const: "fr", title: "French" },
        ],
      },
      // pronouns: {
      //   type: "string",
      //   title: "Pronouns",
      //   default: "",
      //   oneOf: [
      //     { const: "", title: ""},
      //     { const: "She/Her", title: "She/Her" },
      //     { const: "He/Him", title: "He/Him" },
      //     { const: "They/Them", title: "They/Them" },
      //   ],
      // },
      new_password: {
        type: "string",
        format: "password",
        title: "New Password",
        minLength: 6,
      },
      confirm_password: {
        type: "string",
        format: "password",
        title: "Confirm Password",
        minLength: 6,
      },
    },
  };

  const ui_schema = {
    email: {
      //      "ui:autofocus": true,
      // "ui:description": "Login Username",
    },
    "ui:submitButtonOptions": {
      submitText: "Update",
      norender: false,
      props: {
        disabled: false,
        className: "btn btn-info",
      },
    },
    mobile_phone: {
      "ui:widget": "PhoneNumberInput",
    },
    home_phone: {
      "ui:widget": "PhoneNumberInput",
    },
  };

  useEffect(() => {
    async function loadCurrentUser() {
      const { data } = await supabaseClient.from('profiles').select('*').eq('id', userState.currentUser.id);
      setFormData(data[0]);
      return data[0];
    }

    loadCurrentUser();
  }, []);

  const formSubmit = async ({ formData }, e) => {
    console.log("Data submitted: ", formData);
    if (formRef.current.validateForm()) {
      setLoading(true);

      if ('new_password' in formData){
        const new_password = formData.new_password;
        const { user, error } = await supabaseClient.auth.updateUser({password: new_password})
        delete(formData.new_password);
        delete(formData.confirm_password);
      }

      const { data, error } = await supabaseClient.from('profiles').update(formData).eq('id', userState.currentUser.id)

      if (error) {
        console.log("FORM SUBMIT ERROR", error)
        uiDispatch({
          type: "SET_MESSAGE",
          payload: {
            color: "error",
            display: true,
            text: error,
          },
        });
      } else {
        setFormData(data);
        uiDispatch({
          type: "SET_MESSAGE",
          payload: { color: "success",
          display: true,
          text: "Profile Updated!" },
        });
      }

      // setPages(data);
      setLoading(false);
      return data;


      // await authBackend({
      //   method: "put",
      //   path: `user/${userState.currentUser.id}`,
      //   payload: formData,
      // })
      //   .then((data) => {
      //     // console.log("Current User", userState.currentUser.id);
      //     // console.log(data);
      //     setFormData(data.result);

      //     uiDispatch({
      //       type: "SET_MESSAGE",
      //       payload: {
      //         color: "success",
      //         display: true,
      //         text: "Profile Updated!",
      //       },
      //     });

      //     // setPages(data);
      //     setLoading(false);
      //     return { data };
      //   })
      //   .catch((err) => {
      //     setLoading(false);

      //     if (err && err.response) {
      //       if (err.response.status === 422) {
      //         setError({ ...err.response.data.error });
      //       }

      //       if (err.response.status === 400) {
      //         uiDispatch({
      //           type: "SET_MESSAGE",
      //           payload: {
      //             color: "error",
      //             display: true,
      //             text: err.response.data.error,
      //           },
      //         });
      //       }
      //     }
      //     return err;
      //   });
    }
  };

  function customValidate(formData, errors, uiSchema) {
    userExists(formData.email).then((res) => {
      var extra_errors = {};
      if (res) {
        extra_errors["email"] = {
          __errors: ["Email already exists!"],
        };
      }
      setExtraErrors(extra_errors);
    });

    // if (onValidate != undefined) {
    //   const customError = onValidate(formData);
    //   if (customError) {
    //     const field = customError.field;
    //     errors[field].addError(customError.message);
    //   }
    // }
    return errors;
  }

  const formRef = createRef();

  const theme = createTheme(themeOptions);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box
          // width={"80%"}
          display="flex"
          alignItems="center"
          justifyContent="center"
          m="auto"
        >
          <Paper variant="elevation" elevation={2}>
            <Grid
              container
              direction="column"
              justify="center"
              // spacing={2}
              padding={2}
            >
              <Grid item>
                <Typography variant="h5" gutterBottom>
                  My Profile
                </Typography>
              </Grid>
              <Grid item>
                <Form
                  formData={formData}
                  schema={schema}
                  uiSchema={ui_schema}
                  customValidate={customValidate}
                  validator={validator}
                  onSubmit={formSubmit}
                  extraErrors={extraErrors}
                  widgets={{
                    PhoneNumberInput: PhoneNumberInput,
                  }}
                  showErrorList="none"
                  ref={formRef}
                />
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default Profile;
