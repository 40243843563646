import React, { useContext, useState } from "react";

import {
    Box,
    Button,
    Container,
    Divider,
    Grid,
    Stack,
    Paper,
    Typography,
    Modal,
    CircularProgress,
    Link,
} from "@mui/material";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const selectSiteModal = ({ sitesList }) => {
    const [open, setOpen] = useState(false);

    const [current_subdomain, ...rest_hostpath] = window.location.hostname.split(".");
    const base_domain = rest_hostpath.join('.');

    const handleClick = (e, item) => {
        const redirect = `${window.location.protocol}//${item.subdomain}.${base_domain}/login/${item.magic_link.hashed_token}`;
        console.log("Redirecting to:", redirect);
        window.location.assign(redirect)
    }

    const handleClose = (event, reason) => { }

    return (
        <div>
            <Modal
                open={sitesList.length > 0}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box style={style} >
                    <Paper variant="elevation" elevation={2} sx={{ p: 4, m: 4 }}>
                        <Stack spacing={3}>
                            <Typography>Please select the SeniorSynCare site you would like to access:</Typography>
                            {sitesList.map((item) => {
                                return (<Button
                                    onClick={(e) => { handleClick(e, item) }}
                                    variant={item.recipients.type == "recipient" ? "contained" : "outlined"}>
                                    {item.recipients.first_name} {item.recipients.last_name}
                                </Button>)
                            })}
                        </Stack>
                    </Paper>
                </Box>
            </Modal>
        </div>
    );
}

export default selectSiteModal;
