import axios from "axios";
// const url = 'http://192.168.1.114:12001'
const url = window.location.protocol + '//' + window.location.hostname

// Schedule Blocks Impelmentation
//  https://codesandbox.io/s/custom-cell-action-n02dv?file=/src/App.js:608-767

// TEST CONFIGURATION
// export const PayPalConfig = {
//   clientId:
//     "AZOTLwjUxzc00l-GnHIDjB2dEmYgkvUUwGMeX0nRBf1tPjNXAzkgiojthiOpjCQZHX3Jp2CIo-FwlC7i",
//   plans: [
//     {
//       const: "P-0S733165NL516073GMTRHUZA",
//       title: "Monthly [ $24.99/month + tax ]",
//     },
//     {
//       const: "P-7LR26803SC7708426MTRHVZI",
//       title: "Semi-Annual (15% discount) [ $21.25/month + tax ]",
//     },
//   ],
// };

const userExists = async (username = "") => {
  const { data } = await axios({
    url: `${url}/public/finduser`,
    params: { "email": username }
  });
  if (data) {
    return data.exists;
  }
};

export const formConfig =
{
  // state 0
  title: "My Account",
  formData: {},
  schema: {
    title: "User Profile",
    // "description": "ThinkBeyond Login",
    type: "object",
    required: [
      "email",
      "first_name",
      "last_name",
      "new_password",
      "confirm_password",
      "subscription",
    ],
    properties: {
      email: {
        type: "string",
        format: "email",
        title: "Email",
        minLength: 3,
      },
    },
  },
  ui_schema: {
    email: {
      "ui:autofocus": true,
      "ui:help": "Double check to make sure your email is typed correctly.",
      // "ui:description": "Login Username",
    },

    "ui:submitButtonOptions": {
      submitText: "Update",
      norender: false,
      props: {
        disabled: false,
        className: "btn btn-info",
      },
    },

  },

  validate: async (data, errors, uiSchema) => {
    // console.log("CHECKING USER EXISTS")
    // return await userExists(data.email).then((res) => {
    //   var extra_errors = {};
    //   console.log("EMAIL EXISTS", res);
    //   if (res) {
    //     console.log("RETURNING ERRORS")
    //     errors.email.addError("Email already exists!")
    //     // extra_errors["email"] = {
    //     //   __errors: ["Email already exists!"],
    //     // };
    //   }
    //   return errors;
    //   // if (Object.keys(extra_errors).length !== 0) {
    //   //   falseHandler(extra_errors);
    //   // } else {
    //   //   trueHandler();
    //   // }
    // });
  },
};

// function customValidate(formData, errors, uiSchema) {
//   if (formData.pass1 !== formData.pass2) {
//     errors.pass2.addError("Passwords don't match");
//   }
//   return errors;
// }