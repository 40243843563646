import React, { useContext, useState, useEffect } from "react";

import axios from "axios";

import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  Link,
} from "@mui/material";

import Form from "@rjsf/mui";

import { RJSFSchema, UiSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv6";

// import { authBackend } from "../Backend";

import { fetchCurrentUser } from "../Auth/AuthService";

import jwtDecode from "jwt-decode";
// import { withTheme } from '@rjsf/core';
// import Theme from '@rjsf/mui';
// Make modifications to the theme with your own fields and widgets
// const Form = withTheme(Theme);

import { UIContext, UserContext } from "../../App";

const PagesSetup = () => {
  const { uiDispatch } = useContext(UIContext);
  const [loading, setLoading] = useState(false);
  const { userState, userDispatch } = useContext(UserContext);
  const [ formData, setFormData] = useState({});

  const token = localStorage.token && JSON.parse(localStorage.token);

  const schema = {
    title: "User Profile",
    // "description": "ThinkBeyond Login",
    type: "object",
    required: ["email"],
    properties: {
      email: {
        type: "string",
        format: "email",
        title: "Email",
        minLength: 3,
      },
      first_name: {
        type: "string",
        title: "First Name",
        minLength: 3,
      },
      last_name: {
        type: "string",
        title: "Last  Name",
        minLength: 3,
      },
      new_password: {
        type: "string",
        format: "password",
        title: "New Password",
        minLength: 3,
      },
      confirm_password: {
        type: "string",
        format: "password",
        title: "Confirm Password",
        minLength: 3,
      },
    },
  };
  
  const ui_schema = {
    email: {
      "ui:autofocus": true,
      // "ui:description": "Login Username",
    },
    "ui:submitButtonOptions": {
      submitText: "Update",
      norender: false,
      props: {
        disabled: false,
        className: "btn btn-info",
      },
    },
  };


  useEffect(() => {

    async function loadCurrentUser() {
      if (token) {

        const currentUser = await fetchCurrentUser();
        // console.log("Profile Current User", currentUser)

        setFormData({
          email: currentUser.data.user.username,
          first_name: currentUser.data.user.first_name,
          last_name: currentUser.data.user.last_name
        });
        
      }
    }

    loadCurrentUser();
  }, []);


  const formSubmit = async ({ formData }, e) => {
    // console.log("Data submitted: ", formData);

    setLoading(true);

    


    try {
      let token = userState.isLoggedIn ? JSON.parse(localStorage.token) : "";

      const headers = userState.isLoggedIn
        ? { headers: { Authorization: `Bearer ${token}` } }
        : {};

      const { data } = await axios.post(
        `${url}/api/auth/update`,
        initialState,
        headers
      );

      const me = await fetchCurrentUser();
      setLoading(false);

      userDispatch({ type: "SET_CURRENT_USER", payload: me.data.user });

      uiDispatch({
        type: "SET_MESSAGE",
        payload: { color: "success", display: true, text: data.message },
      });

      navigate("/home");
    } catch (err) {
      setLoading(false);

      console.log(err);
      if (err && err.response) {
        if (err.response.status === 422) {
          setError({ ...err.response.data.error });
        }

        if (err.response.status === 400) {
          uiDispatch({
            type: "SET_MESSAGE",
            payload: {
              color: "error",
              display: true,
              text: err.response.data.error,
            },
          });
        }
      }
    }
  };

  return (
    <>
      <Box
        width={"80vh"}
        display="flex"
        alignItems="center"
        justifyContent="center"
        m="auto"
      >
        <Paper variant="elevation" elevation={2}>
          <Grid
            container
            direction="column"
            justify="center"
            spacing={2}
            padding={2}
          >
            <Grid item>
              <Form
                formData={formData}
                schema={schema}
                uiSchema={ui_schema}
                validator={validator}
                onSubmit={formSubmit}
              />
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  );
};

export default PagesSetup;
