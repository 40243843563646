import React, {
  useContext,
  useEffect,
  useState,
  Fragment,
  createRef,
  useRef,
} from "react";

import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";

import {
  Box,
  Button,
  Container,
  Divider,
  CssBaseline,
  Grid,
  Paper,
  Typography,
  Link,
  InputLabel,
  Select,
  MenuItem,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";

import Form from "@rjsf/mui";
import jwtDecode from "jwt-decode";

import { RJSFSchema, UiSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv6";

import TermsAndConditionsWidget from "./TermsAndConditionsWidget";
import FileWidget from "./FileWidget";
import { FilesInfoWidget } from "./FileWidget";
import { RecipientSubmitModal } from "./RecipientSubmitModal";
import { authBackend, publicBackend } from "../Backend";

import { PhoneNumbersWidget } from "./PhoneNumbersWidget";

import { PhoneNumberInput } from "../PhoneNumberInput";

import PayPalSubscribe from "./PayPalSubscribe";

// import { PayPalConfig } from "./new_site";

// const url = 'http://192.168.1.114:12001'
const url = window.location.protocol + '//' + window.location.hostname
import axios from "axios";


// import { withTheme } from '@rjsf/core';
// import Theme from '@rjsf/mui';
// Make modifications to the theme with your own fields and widgets
// const Form = withTheme(Theme);

// https://codesandbox.io/s/agitated-mcnulty-c1bxd8?file=/src/components/SelectStepper/Steps/Step1.js

// https://mui.com/material-ui/react-stepper/

import { UIContext } from "../../App";

// import { steps } from "./registerdata";

import { newUserPayPalConfig, newUserSteps } from "./RecipientNewUserConfig";
import { existingUserPayPalConfig, existingUserSteps } from "./RecipientExistingUserConfig";

import { createTheme, ThemeProvider, styled } from "@mui/material/styles";

import { themeOptions } from "./themeOptions";

// Schedule Blocks Impelmentation
//  https://codesandbox.io/s/custom-cell-action-n02dv?file=/src/App.js:608-767
const RecipientRegister = () => {
  const [activeStage, setActiveStage] = useState(0);
  const [activeState, setActiveState] = useState(0);
  const [completed, setCompleted] = useState({});
  const [extraErrors, setExtraErrors] = useState({});
  const [solutionProvided, setSolutionProvided] = useState(true);

  const { uiDispatch } = useContext(UIContext);

  const [submitting, setSubmitting] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const [formKey, setFormKey] = useState(Date.now());


  let { token } = useParams();

  const decodeToken = jwtDecode(token);
  console.log("JWT DECODE");
  console.log(decodeToken);

  const navigate = useNavigate();
  // const [stepHistory, setStepHistory] = [];
  // const wiz_data = steps.map((item) => item.formData);

  var stateHistory = useRef([]);
  var steps;
  var initialData;
  var PayPalConfig;
  if (decodeToken.regtype == "recipient") {
    initialData = {
      email: decodeToken?.email,
      regtype: decodeToken?.regtype
    };
    steps = newUserSteps;
    PayPalConfig = newUserPayPalConfig;
  } else if (decodeToken.regtype == "existing") {
    initialData = {
      email: decodeToken?.email,
      first_name: "existing",
      last_name: "existing",
      new_password: "existing",
      confirm_password: "existing",
      regtype: decodeToken?.regtype
    };
    steps = existingUserSteps;
    PayPalConfig = existingUserPayPalConfig;
  } else if (decodeToken.regtype == "existing2") {
    initialData = {
      email: decodeToken?.email,
      regtype: decodeToken?.regtype
    };
    steps = existingUserSteps;
    PayPalConfig = existingUserPayPalConfig;
  }


  var stages = [];

  console.log("RECIPIENT REGISTRATION");

  steps.forEach((item, index) => {
    initialData = { ...initialData, ...item.formData };
    if (stages.indexOf(item.title) < 0) {
      stages.push(item.title);
    }
  });

  const [currentData, setCurrentData] = useState(initialData);

  const isStepOptional = (step) => {
    return steps[step].optional === true;
  };

  const nextPage = () => {
    stateHistory.current.push(activeState);

    if (steps[activeState].nextState !== undefined) {
      const next_state = steps[activeState].nextState(currentData);
      setActiveState(next_state);
      setActiveStage(stages.indexOf(steps[next_state].title));
    } else {
      setActiveState(activeState + 1);
      setActiveStage(stages.indexOf(steps[activeState + 1].title));
    }
    updateCompleted(true);
  };

  const formSubmit = async (formData) => {
    // const texting_setup = JSON.parse(formData.texting_setup);
    // const submit_data = { ...formData, texting_setup };



    var submit_data = formData;

    if (!('recipient_poa_forms' in formData)) {
      submit_data.recipient_poa_forms = []
    }

    console.log("Data submitted: ", submit_data);
    console.log("JASON TEST 1")
    setSubmitting(true);

    // await publicBackend({
    //   method: "post",
    //   path: "registration/register",
    //   // payload: submit_data,
    //   payload: JSON.stringify({"email": "jwright@thinkbeyond.ai"}),
    //   // payload: submit_data,
    //   headers: {
    //     'Accept': 'application/json',
    //     'Content-Type': 'application/x-www-form-urlencoded'
    //   },

    // })

    await axios({
      method: "post",
      url: `${url}/registration/register`,
      // data: JSON.stringify({ "email": "jwright@thinkbeyond.ai" }),
      data: { "email": "jwright@thinkbeyond.ai" },
      data: submit_data
      // headers: {
      //   'Accept': 'application/json',
      //   'Content-Type': 'application/x-www-form-urlencoded'
      // }
    })
      .then((data) => {
        console.log("Returned submission data", data);
        setCurrentData({ ...currentData, ...data.result });
        setShowModal(true);
        setSubmitting(false);
      })
      .catch((err) => {
        console.log("Registration Submission ERROR");
        console.log(err);

        setSubmitting(false);
        if ('data' in err.response) {
          uiDispatch({
            type: "SET_MESSAGE",
            payload: {
              color: "error",
              display: true,
              text: err.response.data.error,
            },
          });
        } else {
          uiDispatch({
            type: "SET_MESSAGE",
            payload: {
              color: "error",
              display: true,
              text: "Registration Error!  Please contact administrator.",
            },
          });
        }
      });

    // // setLoading(true);
    // try {
    //   const { data } = await axios.post(`${url}/api/v1/registration/session/`, formData);
    //   // localStorage.setItem("token", JSON.stringify(data.data.token));

    //   // const me = await fetchCurrentUser();
    //   // setLoading(false);

    //   // uiDispatch({
    //   //   type: "SET_MESSAGE",
    //   //   payload: { color: "success", display: true, text: data.message },
    //   // });

    // } catch (err) {
    //   // setLoading(false);

    //   console.log(err);
    //   if (err && err.response) {
    //     if (err.response.status === 422) {
    //       // setError({ ...err.response.data.error });

    //     }

    //     if (err.response.status === 400) {
    //       // uiDispatch({
    //       //   type: "SET_MESSAGE",
    //       //   payload: {
    //       //     color: "error",
    //       //     display: true,
    //       //     text: err.response.data.error,
    //       //   },
    //       // });
    //     }
    //   }
    // }
  };

  const handleApproved = (data, details) => {
    console.log("SUBSCRIPTION APPROVED: ", data, details);
    console.log("CURRENT DATA", currentData);

    formSubmit({ ...currentData, orderID: data.orderID, subscriptionID: data.subscriptionID });
    return;
  };

  const handleNext = () => {
    // setExtraErrors({});
    if (activeState === steps.length - 1) {
      // This is the final state!

      // console.log("FORM SUBMIT!");
      // console.log(currentData);
      formSubmit({ ...currentData, orderID: "", subscriptionID: "" });
      return;
    }
    // return;
    if (formRef.current.validateForm()) {
      var errors = {};
      setExtraErrors();
      if (steps[activeState].validate !== undefined) {
        const extra_errors = steps[activeState]
          // steps[activeState]
          .validate(
            currentData,
            () => {
              setExtraErrors({});
              nextPage();
            },
            (errors) => {
              setExtraErrors(errors);
            }
          );
      }

      setFormKey(Date.now());

      // .then((res) => {
      //   console.log("EXTRA ERRORS 3", res);
      //   setExtraErrors(res);
      // });

      // if (extra_errors !== undefined) {
      //   // errors = steps[activeState].validate(
      //   //   currentData,
      //   // );
      //   console.log("CHECK ERRORS:", extra_errors);
      //   extraErrors.then((res) => {
      //     console.log("CHECK ERRORS 3", res);
      //   });

      //   // nextPage,
      //   // (errors) => {
      //   //   console.log("VALIDATE ERRORS: ", errors)
      //   //   setExtraErrors(errors);
      //   // }

      //   // setExtraErrors(errors);
      // } else {
      //   nextPage();
      // }

      // console.log("EXTRA ERRORS");
      // console.log(extra_errors);
    }
  };

  const handleChange = ({ formData }, e) => {
    setCurrentData({ ...currentData, ...formData });
    if (formRef.current && formRef.current.validateForm()) {
      updateCompleted(true);
    }
  };

  const handleBack = () => {
    const prev_state = stateHistory.current.pop();
    setActiveState(prev_state);
    setActiveStage(stages.indexOf(steps[prev_state].title));
  };

  const formRef = createRef();

  const updateCompleted = (isCompleted = false) => {
    const newCompleted = completed;
    newCompleted[activeStage] = isCompleted;
    setCompleted(newCompleted);
    setSolutionProvided(isCompleted);
  };

  useEffect(() => {
    if (formRef.current.validateForm()) {
      updateCompleted(true);
      setSolutionProvided(true);
      setExtraErrors({});
    } else {
      updateCompleted(false);
      setSolutionProvided(false);
      setExtraErrors({});
    }
  }, [activeState]);

  const customWidgets = {
    FileWidget: FileWidget,
    FilesInfoWidget: FilesInfoWidget,
    PhoneNumbersWidget: PhoneNumbersWidget,
    PhoneNumberInput: PhoneNumberInput,
    TermsAndConditionsWidget: TermsAndConditionsWidget,
  };

  const theme = createTheme(themeOptions);

  return (
    <>
      <ThemeProvider theme={theme}>
        <div>
          <Container component="main" maxWidth="xs">
            {/* <CssBaseline />        */}
            {/* <h4>{activeStep}</h4> */}
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <RecipientSubmitModal
                modalOpen={showModal}
                submitting={submitting}
                formData={currentData}
              />

              <Typography
                component="h1"
                variant="h4"
                sx={{
                  color: "#095296",
                  // textTransform: "uppercase",
                  fontWeight: 700,
                }}
              >
                SeniorSynCare Registration
              </Typography>
            </Box>
            <Box noValidate sx={{ mt: 1, p: 1 }}>
              {/* Stepper Module */}
              <Box
                sx={{
                  marginTop: 3,
                  alignItems: "center",
                }}
              >
                {/* <Stepper activeStep={activeStep} orientation="vertical"> */}
                <Stepper nonLinear activeStep={activeStage}>
                  {/* <Stepper activeStep={activeStep}> */}
                  {/* {stages.map((stepTitle, index) => { */}
                  {stages.map((stepTitle, index) => {
                    const stepProps = { completed: completed[index] };
                    const labelProps = {};
                    // if (isStepSkipped(index)) {
                    //     stepProps.completed = false;
                    // }
                    return (
                      <Step key={stepTitle} {...stepProps}>
                        <StepLabel {...labelProps}>{stepTitle}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </Box>

              {/* Active Step  */}
              {activeState !== steps.length ? (
                <>
                  <Grid container>
                    <Grid item xs={12}>
                      {/* <React.Suspense fallback="Loading Form View..">
                  {view}
                </React.Suspense> */}
                      {/* "hello" */}
                      <Form
                        key={formKey}
                        formData={currentData}
                        schema={steps[activeState].schema}
                        uiSchema={steps[activeState].ui_schema}
                        validator={validator}
                        onSubmit={() => handleNext()}
                        onChange={handleChange}
                        extraErrors={extraErrors}
                        // onError={onError}
                        showErrorList="none"
                        ref={formRef}
                        widgets={customWidgets}
                      // liveValidate
                      >
                        <Fragment />
                      </Form>
                    </Grid>
                  </Grid>
                  {/* {((activeState === steps.length - 1) && (currentData.discount_code !== "SSCFREE")) &&
                    (<PayPalSubscribe
                      plan_id={currentData.subscription}
                      onApprove={handleApproved}
                      PayPalConfig={PayPalConfig}
                    />)
                  } */}
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    {activeState > 0 && (
                      <>
                        <Button
                          color="inherit"
                          disabled={activeState === 0}
                          onClick={handleBack}
                          sx={{ mr: 1 }}
                        >
                          Back
                        </Button>
                      </>
                    )}
                    <Box sx={{ flex: "1 1 auto" }} />
                    {isStepOptional(activeState) && (
                      <>
                        {allowSkip && (
                          <Button
                            color="inherit"
                            onClick={handleSkip}
                            sx={{ mr: 1 }}
                          >
                            Skip
                          </Button>
                        )}
                      </>
                    )}
                    {/* {((activeState !== steps.length - 1) || (currentData.discount_code === "SSCFREE")) && (
                      <Button
                        variant="contained"
                        // disabled={!solutionProvided}
                        onClick={() => handleNext()}
                      >
                        {activeState === steps.length - 1 ? "Submit" : "Next"}
                      </Button>
                    )} */}
                    <Button
                      variant="contained"
                      // disabled={!solutionProvided}
                      onClick={() => handleNext()}
                    >
                      {activeState === steps.length - 1 ? "Submit" : "Next"}
                    </Button>
                  </Box>
                </>
              ) : (
                <></>
              )}
            </Box>
          </Container>
        </div>
      </ThemeProvider>
    </>
  );
};

export default RecipientRegister;
