import React from "react";
import { Link } from "react-router-dom";

import { useState } from "react";

import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Menu,
  Container,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
// import { Menu as MenuIcon, Adb as AdbIcon } from "@mui/icons-material";

// import { UIContext, UserContext } from "../App";

const Navbar = ({ menuItems }) => {
  // function stringToColor(string) {
  //   let hash = 0;
  //   let i;

  //   /* eslint-disable no-bitwise */
  //   for (i = 0; i < string.length; i += 1) {
  //     hash = string.charCodeAt(i) + ((hash << 5) - hash);
  //   }

  //   let color = "#";

  //   for (i = 0; i < 3; i += 1) {
  //     const value = (hash >> (i * 8)) & 0xff;
  //     color += `00${value.toString(16)}`.slice(-2);
  //   }
  //   /* eslint-enable no-bitwise */

  //   return color;
  // }

  // function stringAvatar(name) {
  //   // const displayName = `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`;
  //   const displayName = name.split(" ")[0][0];
  //   return {
  //     sx: {
  //       bgcolor: stringToColor(name),
  //     },
  //     children: displayName,
  //   };
  // }

  // const navigate = useNavigate();

  // const { uiDispatch } = useContext(UIContext);
  // const { userState, userDispatch } = useContext(UserContext);

  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  // const [anchorElUser, setAnchorElUser] = useState(null);
  //
  // const handleOpenUserMenu = (event) => {
  //   setAnchorElUser(event.currentTarget);
  // };
  // const handleCloseUserMenu = () => {
  //   setAnchorElUser(null);
  // };

  // See https://blog.logrocket.com/how-create-multilevel-dropdown-menu-react/

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          component="nav"
          style={{ backgroundColor: "primary", color: "default" }}
          // sx={{ display: { xs: "block", sm: "none" } }}
          sx={{ display: { xs: "block", md: "none" } }}
          position="static"
        >
          <Container maxWidth="x1">
            <Toolbar disableGutters>
              {/* <Typography
              variant="h6"
              noWrap
              component={Link}
              to="/home"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              W4G
            </Typography> */}
              {/* <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}> */}
              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  {menuItems
                    .filter((item) => item.allowed)
                    .map((item, index) => (
                      // {pages.map((page) => (
                      <MenuItem
                        key={index}
                        component={Link}
                        to={item.path}
                        onClick={handleCloseNavMenu}
                      >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primaryTypographyProps={{ style: { color: '#242420' } }} primary={item.name} />
                      </MenuItem>
                    ))}
                </Menu>
              </Box>
              {/* <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />
            <Typography
              variant="h5"
              noWrap
              component="a"
              href=""
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              LOGO
            </Typography> */}
              {/* <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages.map((page) => (
                <Button
                  key={page}
                  component={Link}
                  to={"/portal/" + page.toLowerCase()}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  {page}
                </Button>
              ))}
            </Box> */}

              {/* <Box sx={{ flexGrow: 0 }}>
              {userState.isLoggedIn && (
                <>
                  <Tooltip title="Open Profile">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <Avatar {...stringAvatar(userState.currentUser.name)} />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {settings.map((setting) => (
                      <MenuItem
                        key={setting}
                        component={Link}
                        to={"/portal/" + setting.toLowerCase()}
                        onClick={handleCloseUserMenu}
                      >
                        <Typography textAlign="center">{setting}</Typography>
                      </MenuItem>
                    ))}
                    <MenuItem key="logout" onClick={handleUserLogout}>
                      <Typography textAlign="center">Logout</Typography>
                    </MenuItem>
                  </Menu>
                </>
              )}

              {!userState.isLoggedIn && (
                <Button
                  key="login"
                  component={Link}
                  to="/portal/auth"
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  Login
                </Button>
              )}
            </Box> */}
            </Toolbar>
          </Container>
        </AppBar>
      </Box>
    </>
  );
};

export default Navbar;
