import React, { useContext, useEffect, useState } from "react";

import { useParams, useNavigate } from "react-router-dom";

import { UIContext, UserContext } from "../../App";

import { supabaseClient } from "../supabase";
import { loadUsers } from "../Users/useUsers";
import { loadRelationships } from "../Relationships/useRelationships";


import { fetchCurrentUser } from "./AuthService";

const AutoLogin = () => {
  const { uiDispatch } = useContext(UIContext);
  const [loading, setLoading] = useState(false);
  const { userState, userDispatch } = useContext(UserContext);

  const navigate = useNavigate();

  let { tokenHash } = useParams();

  console.log("AUTO LOGIN 2");

  const loadUser = async (user) => {
    userDispatch({
      type: "SET_CURRENT_USER",
      payload: user,
    });

    await loadRelationships().then((data) => {
      userDispatch({
        type: "SET_RELATIONSHIPS",
        payload: data,
      });
    });

    await loadUsers().then((data) => {
      userDispatch({
        type: "SET_USERS",
        payload: data,
      });
    });

  };

  const setLogin = async () => {
    console.log("AutoLogin setting token.", tokenHash);
    // console.log(loginToken);

    const { data, error } = await supabaseClient.auth.verifyOtp({ token_hash: tokenHash, type: 'magiclink' });

    // console.log("DATA", data);
    // console.log("ERROR", error);

    if (data?.user) {
      const user_data = data.user;
      const { data: profiles } = await supabaseClient.from('profiles').select('id,first_name,last_name,recipient_users(relationship_types(id,name,access_logs, access_schedule))').eq('id', user_data.id);
      var profile = profiles[0];

      if (profile.recipient_users.length === 0){
        window.location.assign(`${window.location.protocol}//${window.location.hostname}/`);
      }

      profile.relationship = profile.recipient_users[0].relationship_types;

      console.log("USER PROFILE DATA", profile);

      localStorage.setItem("profile", JSON.stringify(profile));
      await loadUser(profile);
      console.log("NAVIGATING TO ROOT")
      // window.location.assign(`${window.location.protocol}//${window.location.hostname}/`);
      navigate('/');
    } else {
      // console.log("Bad Login");
      // navigate('/login');
    }

    // localStorage.setItem("token", JSON.stringify(loginToken));

    // window.location.assign(`${window.location.protocol}//${window.location.hostname}/home`);
    return;

    fetchCurrentUser()
      .then((me) => {
        // uiDispatch({
        //   type: "SET_MESSAGE",
        //   payload: {
        //     color: "success",
        //     display: true,
        //     text: "Successful Login",
        //   },
        // });
        // console.log("AutoLogin fetchCurrentUser ");
        // console.log("Setting user to ", me);
        // console.log("Setting user to ", me.data.user);
        // userDispatch({ type: "SET_CURRENT_USER", payload: me.data.user });

        // uiDispatch({
        //   type: "SET_MESSAGE",
        //   payload: {
        //     color: "success",
        //     display: true,
        //     text: "Successful Login",
        //   },
        // });

        window.location.assign(`http://${window.location.hostname}/`);
        // navigate("/home");
      })
      .catch((err) => {
        console.log("AutoLoginfetchCurrentUser ERROR");
        console.log(err);
      });
  };

  useEffect(() => {
    setLogin();
  }, []);

  return (
    <>
      <p>Loading Site...</p>
    </>
  );
};

export default AutoLogin;
