import React, {
    useContext,
    useEffect,
    useState,
    Fragment,
    createRef,
    useRef,
} from "react";

import { Link as RouterLink, useNavigate } from "react-router-dom";

import {
    Box,
    Button,
    Container,
    Divider,
    CssBaseline,
    Grid,
    Paper,
    Typography,
    Link,
    InputLabel,
    Select,
    MenuItem,
    Stepper,
    Step,
    StepLabel,
} from "@mui/material";

import Form from "@rjsf/mui";

import { EmailSubmitModal } from "./EmailSubmitModal";

// const url = 'http://192.168.1.114:12001'
const url = window.location.protocol + '//' + window.location.hostname
import axios from "axios";

import RecipientRegister from "./RecipientRegister";
import UserRegister from "./UserRegister";

const [current_subdomain, ...rest_hostpath] = window.location.hostname.split(".");
const base_domain = rest_hostpath.join('.');



import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { themeOptions } from "./themeOptions";
import validator from "@rjsf/validator-ajv6";

import { UIContext } from "../../App";

import { formConfig } from "./EmailConfig";

const userExists = async (username = "") => {
    const { data } = await axios({
        url: `${url}/public/finduser`,
        params: { "email": username }
    });
    if (data) {
        return data.exists;
    }
};

const EmailRegister = () => {
    const { uiDispatch } = useContext(UIContext);

    const [activeStage, setActiveStage] = useState(0);
    const [activeState, setActiveState] = useState(0);
    const [completed, setCompleted] = useState({});
    const [extraErrors, setExtraErrors] = useState({});
    const [solutionProvided, setSolutionProvided] = useState(true);

    const [submitting, setSubmitting] = useState(true);
    const [showModal, setShowModal] = useState(false);

    const [formKey, setFormKey] = useState(Date.now());

    const initialData = {}

    const [currentData, setCurrentData] = useState(initialData);


    console.log('current_subdomain x', current_subdomain);

    const handleChange = ({ formData }, e) => {
        setCurrentData({ ...currentData, ...formData });
        console.log("handleChange", formRef.current)
        if (formRef.current && formRef.current.validateForm()) {
            updateCompleted(true);
        }
    };
    const formRef = createRef();

    const formSubmit = async (formData) => {

        setSubmitting(true);

        if (await userExists(formData.email)) {
            uiDispatch({
                type: "SET_MESSAGE",
                payload: {
                    color: "error",
                    display: true,
                    text: "User Exists!",
                },
            });
            return
        }


        var submit_data = formData;

        if (current_subdomain.startsWith('cop-')) {
            submit_data.regtype = "user"
            submit_data.relationship = "Member"
            submit_data.subdomain = current_subdomain;
            submit_data.redirect = `${window.location.protocol}//${current_subdomain}.${base_domain}/registeruser`;
        } else if (current_subdomain == 'portal') {
            submit_data.regtype = "recipient"
            submit_data.relationship = "PC"
            submit_data.subdomain = current_subdomain;
            submit_data.redirect = `${window.location.protocol}//${current_subdomain}.${base_domain}/registerrecipient`;
        }

        console.log("Data submitted: ", submit_data);


        await axios({
            method: "post",
            url: `${url}/public/emailverify`,
            // data: JSON.stringify({ "email": "jwright@thinkbeyond.ai" }),
            // data: { "email": "jwright@thinkbeyond.ai" },
            data: submit_data
            // headers: {
            //   'Accept': 'application/json',
            //   'Content-Type': 'application/x-www-form-urlencoded'
            // }
        })
            .then((data) => {
                console.log("Returned submission data", data);
                setCurrentData({ ...currentData, ...data.result });
                setShowModal(true);
                setSubmitting(false);
            })
            .catch((err) => {
                console.log("User Registration Submission ERROR");
                console.log(err);

                setSubmitting(false);
                uiDispatch({
                    type: "SET_MESSAGE",
                    payload: {
                        color: "error",
                        display: true,
                        text: "Registration Error!  Please contact administrator.",
                    },
                });
            });

    };

    const handleNext = () => {
        console.log("handleNext", formRef.current)
        console.log("validate form", formRef.current.validateForm())
        if (formRef.current.validateForm()) {
            var errors = {};
            setExtraErrors();

            const extra_errors = formConfig.validate(
                currentData,
                () => {
                    setExtraErrors({});
                },
                (errors) => {
                    setExtraErrors(errors);
                }
            );

            setFormKey(Date.now());
        } else {

            formSubmit({ ...currentData });
            return;

        }

    }





    const theme = createTheme(themeOptions);

    return (
        <>
            <ThemeProvider theme={theme}>
                <div>
                    <Container component="main" maxWidth="xs">
                        {/* <CssBaseline />        */}
                        {/* <h4>{activeStep}</h4> */}
                        <Box
                            sx={{
                                marginTop: 8,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <EmailSubmitModal
                                modalOpen={showModal}
                                submitting={submitting}
                                formData={currentData}
                            />

                            <Typography
                                component="h1"
                                variant="h4"
                                sx={{
                                    color: "#095296",
                                    // textTransform: "uppercase",
                                    fontWeight: 700,
                                }}
                            >
                                {current_subdomain.startsWith('cop-') ? "SeniorSynCare Community of Practice Registration Request" : "SeniorSynCare User Registration Request"}
                            </Typography>
                        </Box>
                        <Box noValidate sx={{ mt: 1, p: 1 }}>
                            {/* Stepper Module */}
                            {/* Active Step  */}
                            <Grid container>
                                <Grid item xs={12}>
                                    <Form
                                        key={formKey}
                                        formData={currentData}
                                        schema={formConfig.schema}
                                        uiSchema={formConfig.ui_schema}
                                        validator={validator}
                                        customValidate={formConfig.validate}
                                        onSubmit={() => handleNext()}
                                        onChange={handleChange}
                                        extraErrors={extraErrors}
                                        // onError={onError}
                                        showErrorList="none"
                                        ref={formRef}
                                    // widgets={customWidgets}
                                    // liveValidate
                                    >
                                        <Fragment />
                                    </Form>
                                </Grid>
                            </Grid>
                            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                                <Button
                                    variant="contained"
                                    // disabled={!solutionProvided}
                                    onClick={() => handleNext()}
                                >
                                    Submit
                                </Button>
                            </Box>
                        </Box>
                    </Container>
                </div>
            </ThemeProvider >
        </>
    );
}

export default EmailRegister;



