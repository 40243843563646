
import { supabaseClient } from "../supabase";


export async function loadRelationships() {
  // if (token) {
  // const currentUser = await fetchCurrentUser();

  // const querystring = rison.encode({ columns: qcolumns });
  // console.log("Querystring", querystring);

  // http://localhost:5000/api/v1/example/private

  //   const querystring = rison({columns: columns});
  //   console.log("Querystring", querystring);
  const recipient_id = localStorage.recipient_id;

  const { data: relationships } = await supabaseClient
    .from('relationship_types')
    .select('*').eq('recipients_id',recipient_id).order('id');

  return relationships
}
