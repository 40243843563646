import { useEffect, useState } from "react";
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";

// https://medium.com/analytics-vidhya/paypal-subscription-in-react-1121c39b26be


// Single payment article

// Pay and Get Paid 

// https://www.unimedia.tech/paypal-checkout-integration-with-react/

// Sandbox
// API Credentials
// API Key: AZOTLwjUxzc00l-GnHIDjB2dEmYgkvUUwGMeX0nRBf1tPjNXAzkgiojthiOpjCQZHX3Jp2CIo-FwlC7i
// Secret: EGL6DshlSfFf89KXsdQzRoYn5UlYcMF0z7V7HvRBFcSZgNF7V6mUIrC35fu4MbNFNryhDtXrPuZTF1as

// Sandbox credentials
// Username: sb-r47943c27110588@business.example.com
// Password: KfV+k9JP

// Live:
// API Key: ATSa8qjQLXWEvkJ2aTalBw44ZW7I0NlGvUN56GBVMHU9xGDWrXK_DSTeuqtzx-UmEswh_6M5TEcI0sHk
// SecretL EJfFbkdwOX7ta9Cx1w4tS1kxIF_VYOLprb6jA_uA8_8077O-cnk-02Y4qjOvd3vR9NL-lMWp-t_TgCkX




const ButtonWrapper = ({
  type,
  plan_id = "",
  onApprove,
  onError,
  catchError,
  onCancel,
}) => {
  const [{ options }, dispatch] = usePayPalScriptReducer();

  useEffect(() => {
    dispatch({
      type: "resetOptions",
      value: {
        ...options,
        intent: "subscription",
      },
    });
  }, [type]);

  return (
    <>
      <PayPalButtons
        // createSubscription={handleSubscribe}
        createSubscription={(data, actions) => {
          return actions.subscription
            .create({
              plan_id: plan_id,
            })
            .then((orderId) => {
              // Your code here after create the order
              return orderId;
            });
        }}
        onApprove={onApprove}
        onError={onError}
        catchError={catchError}
        onCancel={onCancel}
        style={{
          label: "subscribe",
        }}
      />
    </>
  );
};

export default function PayPalSubscribe({ onApprove, plan_id = "" , PayPalConfig}) {
  const onError = (err) => {
    console.log("SUBSCRIPTION ERROR", err);
  };

  const catchError = (err) => {
    console.log("SUBSCRIPTION CATCH ERROR", err);
  };

  const onCancel = (err) => {
    console.log("SUBSCRIPTION CANCEL", err);
  };

  return (
    <PayPalScriptProvider
      options={{
        clientId: PayPalConfig.clientId,
        components: "buttons",
        intent: "subscription",
        vault: true,
      }}
    >
      {PayPalConfig.plans.map((plan) => {
        // console.log("plan",plan);
        // console.log("plan_id",plan_id);
        return (
          <>
            {plan_id === plan.const && (
              <>
                <ButtonWrapper
                  type="subscription"
                  plan_id={plan_id}
                  onApprove={onApprove}
                  onError={onError}
                  catchError={catchError}
                  onCancel={onCancel}
                />
              </>
            )}
          </>
        );
      })}
    </PayPalScriptProvider>
  );
}
